/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import {
  Box, Grid, Modal, TextField, Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../hook/use-auth';
import { useCreateRealmForAuthenticatedUser } from '../../generated/realmWorldsApiComponents';
import useModal from '../../hook/useModal';
import { Realm, RealmWorldsNewRealmRequest } from '../../generated/realmWorldsApiSchemas';
import useNotification from '../../hook/useNotification';

type CreateRealmForm = {
  realmName: string;
};

type CreateRealmProps = {
  open: boolean,
  onClose?: (realm: Realm | null) => void,
  realmName?: string,
};

export default function CreateRealmModal({
  open,
  onClose,
  realmName,
}: CreateRealmProps) {
  const auth = useAuth();
  const {
    handleSubmit, formState: { errors }, setValue, control,
  } = useForm<CreateRealmForm>();
  const { displayNotification } = useNotification();
  const {
    modalStyle,
  } = useModal();

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutate: createRealm, isLoading: isRealmLoading, error: realmError, data: realm,
  } = useCreateRealmForAuthenticatedUser({
    onSuccess: (data) => {
      onClose?.(data);
      displayNotification({ message: `Realm ${data.name} Created!`, type: 'success' });
    },
    onError: () => {
      onClose?.(null);
      displayNotification({ message: 'Error creating Realm', type: 'error' });
    },
  });

  if (realmError) {
    console.error('getRealms Error: ', realmError);
    // refresh token
    auth?.refreshToken();
  }

  const onSubmit = (data: CreateRealmForm) => {
    const newRealm = {
      name: data.realmName,
      description: '',
      isPublic: true,
      isVisibleInTool: true,
    } as RealmWorldsNewRealmRequest;
    createRealm({
      body: newRealm,
    });
  };

  useEffect(() => {
    setValue('realmName', realmName ?? 'New Realm');
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => { onClose?.(null); }}
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid item mb={4} xs={12} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
              <Typography variant="h4" align="center">Create New Realm</Typography>
            </Grid>
            <Grid item mb={4} xs={12} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
              <Grid container px={10} py={4}>
                <Grid item xs={12} mb={2}>
                  <Controller
                    name="realmName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        {...field}
                        error={Boolean(errors.realmName)}
                        helperText={errors.realmName && 'Realm name is required'}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isRealmLoading}
                      sx={{
                        backgroundColor: '#FF6746', px: 10, mx: 'auto', borderRadius: '3vmin',
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

CreateRealmModal.defaultProps = {
  onClose: () => { },
  realmName: '',
};
