/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import LoginBox from '../auth/LoginBox';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  color: 'white',
  outline: 'none',
};

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  setOpen: (v: boolean) => void,
  onConfirm: () => void
};

export default function WelcomeModal({
  open, onClose, setOpen,
}: RWModalProps) {
  const handleClose = () => { onClose?.(new Event('close'), 'escapeKeyDown'); setOpen(false); };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <LoginBox onLogin={() => handleClose()} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
