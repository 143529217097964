import React, { useEffect } from 'react';

interface DropProps {
  callback: (files: FileList) => void,
  targetDOMElement: HTMLElement | null,
}

export default function Drop({ callback, targetDOMElement }: DropProps) {
  useEffect(() => {
    if (!targetDOMElement) {
      return;
    }
    targetDOMElement.addEventListener('dragenter', (e) => {
      e.stopPropagation();
      e.preventDefault();
    });
    targetDOMElement.addEventListener('dragover', (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!e.dataTransfer) {
        return;
      }
      e.dataTransfer.dropEffect = 'copy';
    });
    targetDOMElement.addEventListener('drop', (e) => {
      e.preventDefault();
      if (!e.dataTransfer) {
        return;
      }

      callback(e.dataTransfer.files);
    }, false);
  }, [targetDOMElement]);

  return (
    <>

    </>
  );
}
