/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/jsx-no-bind */
import React, { PropsWithChildren } from 'react';

import { InstantSearch } from 'react-instantsearch-hooks-web';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

type SearchInterfaceProps = {
  searchKey: string,
  collection: string,
} & PropsWithChildren;

function InstantSearchInterface({
  searchKey, collection, children,
}: SearchInterfaceProps) {
  // console.log('searchKey: ', searchKey)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: searchKey, // Be sure to use a Search API Key
      nodes: [
        {
          host: process.env.REACT_APP_TYPESENSE_HOST ?? '', // where xxx is the ClusterID of your Typesense Cloud cluster
          port: Number(process.env.REACT_APP_TYPESENSE_PORT) ?? 443,
          protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL ?? 'https',
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // 2 minutes
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  queryBy is required.
    additionalSearchParameters: {
      query_by: 'Name,Description',
    },
  });

  const { searchClient } = typesenseInstantsearchAdapter;
  // console.log(searchClient)

  return (
    <InstantSearch searchClient={searchClient} indexName={collection}>
      {children}
    </InstantSearch>
  );
}

export default InstantSearchInterface;
