/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  color: 'white',
  p: 4,
  outline: 'none',
};

type RWModalProps = {
  open: boolean,
  onClose: () => void,
  setOpen?: (v: boolean) => void,
  onConfirm: () => void
} & React.PropsWithChildren;

export default function ConfirmationModal({
  open, onClose, setOpen, children,
}: RWModalProps) {
  const handleClose = () => { onClose?.(); setOpen?.(false); };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 9999 }}
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}

ConfirmationModal.defaultProps = {
  setOpen: () => { },
};
