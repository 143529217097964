/* eslint-disable import/no-extraneous-dependencies */
import { Node, NodeConfig } from 'konva/lib/Node';
import React from 'react';
import useItem from './useItem';
import useTransformer from './useTransformer';

const useHotkeyFunc = () => {
  const { removeItem } = useItem();

  const deleteItems = (
    selectedItems: Node<NodeConfig>[],
    setSelectedItems: (value: React.SetStateAction<Node<NodeConfig>[]>) => void,
    transformerRef: ReturnType<typeof useTransformer>['transformerRef'],
  ) => {
    setSelectedItems([]);
    transformerRef.current?.nodes([]);
    removeItem(selectedItems.map((item) => item.id()));
  };

  return {
    deleteItems,
  };
};

export default useHotkeyFunc;
