import axios from 'axios';
import { apiURI } from '../shared/api.settings';

interface CreateNewUserAccountProps {
  email: string;
  password: string | null;
  displayName: string | null;
  photoUrl?: string;
  userId?: string | null;
  phoneNumber?: string;
  plan?: string;
}

class RealmWorldsService {
  /*
  static GetUserCredits = async (id) => {
      return fetch(`${apiURI}/credits?userId=${id}&extendedInfo=false`)
          .then((response) => response.json());
  }
  */
  static CreateNewUserAccount = async ({
    email,
    password,
    displayName,
    photoUrl = '',
    userId = null,
    plan = 'Free',
  }: CreateNewUserAccountProps) => {
    const body = {
      userId,
      email,
      password,
      displayName,
      photoUrl,
      subscriptionTierName: plan,
    };

    return fetch(`${apiURI}/Users/Signup`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  };

  static UpgradeUserAccount = (userId: string, plan: string) => {
    const body = {
      userId,
      subscriptionTierName: plan,
    };

    return axios.put(`${apiURI}/Users/Upgrade`, body);
  };
}

export default RealmWorldsService;
