import React from 'react';
import './App.css';
import './fonts.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Dashboard from './views/Dashboard';
import Signup from './views/Signup';
import { ProvideAuth } from './hook/use-auth';
import ResetPassword from './views/ResetPassword';
import Login from './views/Login';

const realmWorldsApiQueryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <ProvideAuth>
        <QueryClientProvider client={realmWorldsApiQueryClient}>
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path="signup" element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="password-reset" element={<ResetPassword />} />
          </Routes>
        </QueryClientProvider>
      </ProvideAuth>
    </BrowserRouter>
  );
}

export default App;
