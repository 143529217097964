import * as React from 'react';
import Popover from '@mui/material/Popover';
import {
  Box, IconButton, MenuItem, MenuList,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function EllipsisMenu() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        onClick={handleClick}
        sx={{ width: 42, ml: 1 }}
      >
        <FontAwesomeIcon icon={solid('ellipsis-vertical')} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList sx={{ backgroundColor: '#111111' }}>
          <MenuItem>
            FAQ
          </MenuItem>
          <MenuItem>
            Token Tool
            {' '}
            {process.env.REACT_APP_VERSION || 'v0.0.0-dev'}
          </MenuItem>
        </MenuList>
      </Popover>
    </Box>
  );
}
