/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/require-default-props */
import {
  Link as RouterLink,
} from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../hook/use-auth';
import { fetchCreateRealmWorldsUser } from '../../generated/realmWorldsApiComponents';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

const GoogleButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  height: '60px',
  backgroundColor: 'white',
  borderColor: '#0063cc',
  color: '#757575',
  '&:hover': {
    borderColor: '#0062cc',
    backgroundColor: '#e8e8e8',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    borderColor: '#005cbf',
  },
});

const RMTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '&': {
    backgroundColor: 'rgba(222, 209, 176, 0.7)',
    borderRadius: '4px',
  },
});

const RMFilledInput = styled(FormControl)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '&': {
    backgroundColor: 'rgba(222, 209, 176, 0.7)',
    borderRadius: '4px',
  },
});

type LoginBoxProps = {
  onLogin?: () => void
};

function LoginBox(props: LoginBoxProps): JSX.Element {
  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setError] = useState(false);
  const [authErrorMessage, setErrorMessage] = useState('');

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    setAuthLoading(true);
    try {
      await auth?.signin(email, password);
      // if you get here, you have signed in.
      props?.onLogin?.();
    } catch (error: any) {
      setError(true);
      setAuthLoading(false);
      if (error.code === 'auth/wrong-password') {
        setErrorMessage('Invalid password. Please try again.');
      } else if (error.code === 'auth/too-many-requests') {
        setErrorMessage(
          'Too many failed login attempts. Please try again later, or reset your password.',
        );
      } else if (error.code === 'auth/user-not-found') {
        setErrorMessage(
          'We could not find an account with that email address. Please try again.',
        );
      } else {
        setErrorMessage('An error occurred. Please try again later.');
      }
    }
  };
  const handleLoginWithGoogle = async () => {
    console.log('login with google');
    setAuthLoading(true);
    try {
      const result = await auth?.signInWithPopupFlow();
      // if you get here, you have signed in.
      // check if the user new.
      const userDetails = auth?.getAdditionalUserInfoFlow(result);
      const currentUser = result.user;
      const idToken = await auth?.user?.getIdToken();
      if (userDetails?.isNewUser) {
        // if the user is new, then we need to notify the backend that this is a new user.
        const newUserResult = await fetchCreateRealmWorldsUser({
          body: {
            displayName: currentUser?.displayName,
            email: currentUser?.email,
            photoUrl: currentUser?.photoURL,
            password: null,
            userId: currentUser?.uid,
            subscriptionTierName: 'Free',
          },
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        console.log('new user result: ', newUserResult);
      }
      props?.onLogin?.();
    } catch (error: any) {
      setError(true);
      setErrorMessage('Could not sign in with Google. Please try again.');
    }
  };

  const validateInput = () => {
    // check email regex
    // check password length
    const emailValidated = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email);
    // var passwordValidated =
    // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm.test(password);
    return emailValidated;
  };

  return (
    <Card sx={{
      minWidth: 275, margin: '40px', backgroundColor: '#0000', backgroundImage: 'none', boxShadow: 'none',
    }}
    >
      <CardContent sx={{ backgroundColor: '#0000' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LinkRouter color="#FF6746" to="/signup" variant="body2">
                  <Typography align="center">Need to sign up?</Typography>
                </LinkRouter>
              </Grid>
              <Grid item xs={12}>
                <GoogleButton
                  fullWidth
                  variant="outlined"
                  onClick={handleLoginWithGoogle}
                  startIcon={<Avatar sx={{ width: 24, height: 24 }} src="./images/google_icon.svg" />}
                  sx={{ mt: 0, mb: 2 }}
                >
                  Login with Google
                </GoogleButton>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">Or</Typography>
              </Grid>
              <Grid item xs={12}>
                <RMTextField
                  required
                  fullWidth
                  variant="filled"
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={!validateInput() && !(email.length < 4)}
                  onChange={(e) => { setEmail(e.target.value); }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <RMFilledInput fullWidth variant="filled">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <FilledInput
                    id="outlined-adornment-password"
                    name="password"
                    disableUnderline
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    onChange={(e) => { setPassword(e.target.value); }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )}
                  />
                </RMFilledInput>
              </Grid>
            </Grid>
            {authError ? (
              <FormHelperText
                sx={() => ({
                  mx: 'auto',
                  color: 'red',
                })}
              >
                {authErrorMessage}
              </FormHelperText>
            ) : (
              <></>
            )}
            <LinkRouter color="#FF6746" to="/password-reset" variant="body2">
              Forgot password?
            </LinkRouter>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleLogin}
              loading={authLoading}
              sx={{
                mt: 3, mb: 4, height: 60, backgroundColor: '#FF6746',
              }}
            >
              Log In
            </LoadingButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
    // <Container>
    //   <Typography component="h1" variant="h5">
    //     Sign in
    //   </Typography>
    //   <Box sx={{ mt: 1, maxWidth: 220 }}>
    //     <TextField
    //       margin="normal"
    //       required
    //       fullWidth
    //       id="email"
    //       label="Email Address"
    //       name="email"
    //       autoComplete="email"
    //       error={
    //                     !validateInput() && !(email.length < 4)
    //                 }
    //       onChange={(e) => { setEmail(e.target.value); }}
    //     />
    //     <TextField
    //       margin="normal"
    //       required
    //       fullWidth
    //       name="password"
    //       label="Password"
    //       type="password"
    //       id="password"
    //       autoComplete="current-password"
    //       onChange={(e) => { setPassword(e.target.value); }}
    //     />
    //     {authError ? (
    //       <FormHelperText
    //         sx={() => ({
    //           mx: 'auto',
    //           color: 'red',
    //         })}
    //       >
    //         {authErrorMessage}
    //       </FormHelperText>
    //     ) : (
    //       <></>
    //     )}
    //     <LoadingButton
    //       type="submit"
    //       fullWidth
    //       variant="contained"
    //       sx={{ mt: 3, mb: 2 }}
    //       onClick={handleLogin}
    //       loading={authLoading}
    //     >
    //       Sign In
    //     </LoadingButton>
    //     <Typography align="center">or</Typography>
    //     <Button
    //       type="submit"
    //       fullWidth
    //       variant="outlined"
    //       startIcon={<Avatar sx={{ width: 24, height: 24 }} src="./images/google_icon.svg" />}
    //       sx={{ mt: 3, mb: 2 }}
    //       onClick={handleLoginWithGoogle}
    //     >
    //       with Google
    //     </Button>
    //     <Grid container>
    //       <Grid item xs>
    //         <LinkRouter to="/password-reset" variant="body2">
    //           Forgot password?
    //         </LinkRouter>
    //       </Grid>
    //       <Grid item>
    //         <LinkRouter to="/register" variant="body2">
    //           New around here? Sign up
    //         </LinkRouter>
    //       </Grid>
    //     </Grid>
    //   </Box>
    // </Container>
  );
}

export default LoginBox;
