/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  CircularProgress,
  Grid, IconButton, Menu, MenuItem, TextField, Tooltip, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHits, useInstantSearch } from 'react-instantsearch-hooks-web';
import Image from 'mui-image';
import { Refresh } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  fetchCreateRealmForAuthenticatedUser,
  fetchDeleteRealmById,
  fetchUpdateRealmForAuthenticatedUser,
} from '../../../generated/realmWorldsApiComponents';
import ConfirmationModal from '../../modals/ConfirmationModal';
import useNotification from '../../../hook/useNotification';

// const REACT_APP_URL = process.env.REACT_APP_URL || 'http://localhost:3000';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  color: 'white',
  p: 4,
  outline: 'none',
  backgroundColor: 'rgba(217, 217, 217, 0.4 )',
  border: '1px solid #FFFFFF',
  borderRadius: '10px',
};

interface RealmProps {
  onSetSelectedRealm: (realm: any) => void;
}

function Realms({ onSetSelectedRealm }: RealmProps) {
  const { displayNotification } = useNotification();
  const { refresh, status } = useInstantSearch();
  const { hits } = useHits();
  const [contextMenu, setContextMenu] = useState<{
    [key: string]: {
      mouseX: number;
      mouseY: number;
    } } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openNewRealmModal, setOpenNewRealmModal] = useState<boolean>(false);
  const [openUpdateRealmModal, setOpenUpdateRealmModal] = useState<boolean>(false);
  const [newRealmName, setNewRealmName] = useState<string | null>(null);
  const [realmToUpdate, setRealmToUpdate] = useState<any | null>(null);
  const [realmToDelete, setRealmToDelete] = useState<any | null>(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [confirmDeleteRealmName, setConfirmDeleteRealmName] = useState<string | null>(null);
  const [newRealm, setNewRealm] = useState<any | null>(null);

  // Errors
  const handleContextMenu = (event: React.MouseEvent, id: string) => {
    console.log('handleContextMenu: ', id);
    event.preventDefault();
    setContextMenu({
      ...contextMenu,
      [id]: { mouseX: event.clientX + 2, mouseY: event.clientY - 6 },
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const deleteRealm = async (realmId: string) => {
    displayNotification({ message: 'Deleting realm...', type: 'info' });
    try {
      const deleteRealmResponse = await fetchDeleteRealmById({
        pathParams: {
          realmId,
        },
      });
      if (deleteRealmResponse) {
      // sleep for 2 seconds then refresh
        setLoading(true);
        setTimeout(() => {
          displayNotification({ message: 'Realm deleted', type: 'success' });
          refresh();
          setLoading(false);
          setConfirmDeleteRealmName(null);
          setShowConfirmDeleteModal(false);
        }, 3000);
      } else {
        displayNotification({ message: 'Failed to delete realm', type: 'error' });
      }
    } catch (err) {
      displayNotification({ message: 'Failed to delete realm', type: 'error' });
    }
    handleCloseContextMenu();
  };

  // const viewInExtension = async (realmId: string, tokenId: string) => {
  //   console.log('coming soon: ', realmId, tokenId);
  //   handleCloseContextMenu();
  // };

  // const setTokenFavoriteValue = async (realmId: string, tokenId: string, isFavorited: boolean)
  // => {
  //   setLoading(true);
  //   if (isFavorited) {
  //     const response = await fetchUnFavoriteRealmToken({
  //       pathParams: {
  //         realmId,
  //         tokenId,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${auth?.currentUserToken}`,
  //       },
  //     });
  //     console.log('response: ', response);
  //   } else {
  //     const response = await fetchFavoriteRealmToken({
  //       pathParams: {
  //         realmId,
  //         tokenId,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${auth?.currentUserToken}`,
  //       },
  //     });
  //     console.log('response: ', response);
  //   }

  //   setTimeout(() => {
  //     refresh();
  //     setLoading(false);
  //   }, 3000);
  // };

  const createNewRealm = async () => {
    setLoading(true);
    displayNotification({ message: 'Creating new realm...', type: 'info' });
    try {
      const newRealmResponse = await fetchCreateRealmForAuthenticatedUser({
        body: {
          name: newRealmName,
        },
      });
      if (newRealmResponse !== null) {
        setTimeout(() => {
          displayNotification({ message: 'New realm created', type: 'success' });
          refresh();
          setLoading(false);
          setOpenNewRealmModal(false);
          setNewRealm(newRealmResponse);
        }, 3000);
      } else {
        displayNotification({ message: 'Failed to create new realm', type: 'error' });
      }
    } catch (err) {
      displayNotification({ message: 'Failed to create new realm', type: 'error' });
    }
  };

  const updateRealm = async () => {
    setLoading(true);
    displayNotification({ message: 'Updating realm...', type: 'info' });
    try {
      const updateRealmResponse = await fetchUpdateRealmForAuthenticatedUser({
        pathParams: {
          realmId: realmToUpdate?.id,
        },
        body: {
          name: newRealmName,
        },
      });
      if (updateRealmResponse !== null) {
        setTimeout(() => {
          displayNotification({ message: 'Realm updated', type: 'success' });
          refresh();
          setLoading(false);
          setOpenUpdateRealmModal(false);
        }, 3000);
      } else {
        displayNotification({ message: 'Failed to update realm', type: 'error' });
      }
    } catch (err) {
      displayNotification({ message: 'Failed to update realm', type: 'error' });
    }
  };

  const confirmDeleteRealm = (
    realmId: string,
    ThumbnailUrl: string,
    Name: string,
  ) => {
    setRealmToDelete({
      realmId, ThumbnailUrl, Name,
    });
    setShowConfirmDeleteModal(true);
    console.log('tokenToDelete: ', realmToDelete);
  };

  // useEffect to refresh on load
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        // refresh();
      }, 1000);
    }
    return () => {
      mounted = false;
    };
  }, []);

  if (status === 'loading' || loading) {
    return (
      <Box display="flex" margin="auto" flexDirection="column" justifyContent="center" alignItems="center" sx={{ height: '30vh' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box>
      { newRealm !== null ? (
        <ConfirmationModal
          open={newRealm !== null}
          onClose={() => setNewRealm(null)}
          onConfirm={() => {}}
          setOpen={setShowConfirmDeleteModal}
        >
          <Box sx={style} display="flex" flexDirection="column" alignContent="center" margin="auto">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" textAlign="center">
                  You created a new Realm!
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Image
                  src={newRealm.thumbnailUrl ?? 'images/rw_logo.png'}
                  height="8vw"
                  fit="contain"
                  duration={200}
                />
                <Tooltip title={newRealm.name}>
                  <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    {newRealm.name}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button variant="contained" color="inherit" onClick={() => setNewRealm(null)}>
                    Close
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Box>
        </ConfirmationModal>
      ) : null }
      { realmToDelete !== null ? (
        <ConfirmationModal
          open={showConfirmDeleteModal}
          onClose={() => {}}
          onConfirm={() => {}}
          setOpen={setShowConfirmDeleteModal}
        >
          <Box sx={style} display="flex" flexDirection="column" alignContent="center" margin="auto">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ fontWeight: 600 }} textAlign="center">
                  Are you sure you want to delete this realm?
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Image
                  src={realmToDelete.ThumbnailUrl ?? 'images/rw_logo.png'}
                  height="8vw"
                  fit="contain"
                  duration={200}
                  style={{
                    cursor: 'pointer',
                  }}
                />
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  {realmToDelete.Name.length > 10 ? `${realmToDelete.Name.substring(0, 10)}...` : realmToDelete.Name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 600 }} textAlign="center" color="error">
                  * Deleting this realm will delete all tokens associated with it. *
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: '60%', margin: 'auto' }}>
                  <TextField fullWidth label={`Type the realm name \`${realmToDelete.Name}\` to confirm`} variant="outlined" onChange={(e) => setConfirmDeleteRealmName(e.target.value)} />
                </Box>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <LoadingButton disabled={confirmDeleteRealmName !== realmToDelete.Name} loading={loading} variant="contained" color="primary" onClick={() => deleteRealm(realmToDelete.realmId)}>
                    Delete
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button variant="contained" color="inherit" onClick={() => setShowConfirmDeleteModal(false)}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </Box>
        </ConfirmationModal>
      ) : null }
      <ConfirmationModal
        open={openNewRealmModal}
        setOpen={setOpenNewRealmModal}
        onClose={() => {}}
        onConfirm={() => {}}
      >
        <Box style={style}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              Create a New Realm
            </Typography>
            <Grid container spacing={4} sx={{ marginX: 4 }}>
              <Grid item xs={12} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%', marginTop: 2 }}>
                  <TextField
                    id="outlined"
                    onChange={(e) => setNewRealmName(e.target.value)}
                    label="Realm Name"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    onKeyDown={(e) => { if (e.key === 'Enter') createNewRealm(); else console.log(e.key); }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%' }}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={newRealmName === null || newRealmName === ''}
                    loading={loading}
                    onClick={createNewRealm}
                    sx={{ width: '100%' }}
                  >
                    Create Realm
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%' }}>
                  <Button variant="contained" color="inherit" onClick={() => setOpenNewRealmModal(false)} sx={{ width: '100%' }}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} sx={{ marginTop: 4 }} />
          </Box>
        </Box>
      </ConfirmationModal>
      <ConfirmationModal
        open={openUpdateRealmModal}
        setOpen={setOpenUpdateRealmModal}
        onClose={() => {}}
        onConfirm={() => {}}
      >
        <Box style={style}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              Update Realm:
              {' '}
              {realmToUpdate?.Name ?? 'Unknown'}
            </Typography>
            <Grid container spacing={4} sx={{ marginX: 4 }}>
              <Grid item xs={12} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%', marginTop: 2 }}>
                  <TextField
                    id="outlined"
                    onChange={(e) => setNewRealmName(e.target.value)}
                    label="Realm Name"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    onKeyDown={(e) => { if (e.key === 'Enter') updateRealm(); }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%' }}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={newRealmName === null || newRealmName === ''}
                    loading={loading}
                    onClick={updateRealm}
                    sx={{ width: '100%' }}
                  >
                    Update Realm
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%' }}>
                  <Button variant="contained" color="inherit" onClick={() => setOpenNewRealmModal(false)} sx={{ width: '100%' }}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} sx={{ marginTop: 4 }} />
          </Box>
        </Box>
      </ConfirmationModal>
      <Box sx={{ position: 'absolute', right: 28, top: 50 }}>
        <IconButton size="large" onClick={() => refresh()}>
          <Refresh />
        </IconButton>
      </Box>
      <Grid container spacing={1} sx={{ margin: 0 }}>
        {/* .filter((h) => deletedTokenIds.indexOf(h.objectID) < 0) */}
        <Grid key="new-realm" item xs={2} direction="column-reverse" display="flex">
          <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row-reverse' }}>
            <Box display="flex" flexDirection="column" onClick={() => setOpenNewRealmModal(true)}>
              <Image src="images/icons/Add.png" height="8vw" fit="contain" duration={200} />
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                Add
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                Create a
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                new Realm
              </Typography>
            </Box>
          </div>
        </Grid>
        { hits.map((realm: any) => (

          <Grid key={realm.id} item xs={2} direction="column-reverse" display="flex">

            <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row-reverse' }}>
              {/* <Box sx={{ mt: -2 }}>
                    <IconButton onClick={() => setTokenFavoriteValue(RealmId, id, IsFavorited)}>
                      { IsFavorited ? <Star />
                        : <StarBorder /> }
                    </IconButton>
                  </Box> */}
              <Box display="flex" flexDirection="column" onClick={() => onSetSelectedRealm(realm)} onContextMenu={(e) => { e.preventDefault(); handleContextMenu(e, realm.id); }}>
                <Image src={realm.ThumbnailUrl ?? 'images/rw_logo.png'} height="8vw" fit="contain" duration={200} />
                <Tooltip title={realm.Name} placement="top">
                  <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    {realm.Name.length > 10 ? `${realm.Name.substring(0, 10)}...` : realm.Name}
                  </Typography>
                </Tooltip>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  {'Last Updated '}
                </Typography>
                <Typography variant="caption" sx={{ textAlign: 'center' }}>
                  {new Date(realm.UpdatedAt).toLocaleString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </Typography>
              </Box>

              <Menu
                open={contextMenu !== null
                      && contextMenu[realm.id] !== undefined
                      && contextMenu[realm.id] !== null}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                      contextMenu !== null
                      && contextMenu[realm.id] !== undefined
                      && contextMenu[realm.id] !== null
                        ? { top: contextMenu[realm.id].mouseY, left: contextMenu[realm.id].mouseX }
                        : undefined
                    }
              >
                <MenuItem onClick={() => {
                  setRealmToUpdate({
                    id: realm.id,
                    ThumnailUrl: realm.ThumbnailUrl,
                    Name: realm.Name,
                  });
                  setOpenUpdateRealmModal(true);
                }}
                >
                  Update
                </MenuItem>
                <MenuItem onClick={() => confirmDeleteRealm(
                  realm.id,
                  realm.ThumbnailUrl,
                  realm.Name,
                )}
                >
                  Delete
                </MenuItem>
              </Menu>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default React.memo(Realms);
