import React from 'react';
import { Snackbar, Alert, SnackbarCloseReason } from '@mui/material';
import useNotification from '../../hook/useNotification';

export default function Notification() {
  const { notification, clearNotification } = useNotification();

  const handleClose = (_: unknown, reason?: SnackbarCloseReason) => reason !== 'clickaway' && clearNotification();

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={notification.timeout ?? 4000}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={notification.type}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
}
