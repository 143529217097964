/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import Konva from 'konva';
import { Filter } from 'konva/lib/Node';
import {
  useRef, useMemo, useEffect, MutableRefObject, Fragment, forwardRef, useImperativeHandle,
} from 'react';
import {
  Stage, Layer, Group,
} from 'react-konva';
import {
  Box, Button, Typography,
} from '@mui/material';
import useItem from '../../hook/useItem';
import { StageData } from '../../redux/currentStageData';
import ImageItem, { ImageItemProps } from '../konvaItem/ImageItem';
import ExportImageModal from '../modals/ExportImageModal';
import TextItem, { TextItemProps } from '../konvaItem/TextItem';
import useTransformer from '../../hook/useTransformer';
import { canvasHeight, canvasWidth } from '../CanvasConstants';
import { useAuth } from '../../hook/use-auth';
import useToken from '../../hook/useToken';
import InstantSearchInterface from '../search/RealmWorldsSearchAdapter';
import { useGetSearchKey, useGetUserConfig } from '../../generated/realmWorldsApiComponents';
import ShowInToolModal from '../modals/ShowInToolModal';
import useModal from '../../hook/useModal';
import { Realm } from '../../generated/realmWorldsApiSchemas';

const WIDTH = 260;
const HEIGHT = 260;

export interface PreviewRef {
  savePreview: () => void;
  exportPreview: () => void;
  stage: Konva.Stage;
}

export interface PreviewProps {
  onSave?: () => void;
}

export const Preview = forwardRef<PreviewRef, PreviewProps>((props, ref) => {
  const auth = useAuth();
  const {
    modals,
    openModal,
    closeModal,
  } = useModal();
  const { stageData } = useItem();
  const stageRef = useRef() as MutableRefObject<Konva.Stage>;

  const { updateToken } = useToken();

  const exportPreview = () => {
    const link = document.createElement('a');
    const stage = stageRef.current;
    const uri = stage.toDataURL({
      x: 0,
      y: 0,
      width: WIDTH,
      height: HEIGHT,
      pixelRatio: 1,
    });
    if (uri) {
      link.download = `realm-worlds-project1-${nanoid()}.png`;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const savePreview = () => {
    console.log('saving');
    const image = document.createElement('img');
    const stage = stageRef.current;
    stage.toBlob({
      x: 0,
      y: 0,
      width: WIDTH,
      height: HEIGHT,
      pixelRatio: 1,
      callback: (blob) => {
        image.src = stage.toDataURL({
          x: 0,
          y: 0,
          width: WIDTH,
          height: HEIGHT,
          pixelRatio: 1,
        });

        updateToken((prevData) => ({
          finalImageBlob: blob,
          finalImageSrc: image.src,
          edits: stageData,
          edited: prevData.data?.edited,
        }));
        openModal('export-modal');
      },
    });
  };

  const sortedStageData = useMemo(
    () => {
      if (stageData.length === 0) {
        return [];
      }

      let secondItem = stageData.find((x) => x.id === 'clip-mask') ?? stageData.find((x) => x.id === 'border-background');

      const dataCopy = [...stageData];
      if (secondItem) {
        if (secondItem.id === 'border-background') {
          const attrs = {
            ...secondItem.attrs, filters: ['WhiteMask'], zIndex: 11, brightness: 0, opacity: 1.0,
          };
          secondItem = { ...secondItem, attrs, id: 'clip-mask' };
          dataCopy.push(secondItem);
        }
      }

      return dataCopy.sort((a, b) => {
        if (a.attrs.zIndex === b.attrs.zIndex) {
          if (a.attrs.zIndex < 0) {
            return b.attrs.updatedAt - a.attrs.updatedAt;
          }
          return a.attrs.updatedAt - b.attrs.updatedAt;
        }
        return a.attrs.zIndex - b.attrs.zIndex;
      });
    },
    [stageData],
  );

  const tempTransfomer = useTransformer();

  const renderObject = (item: StageData) => {
    switch (item.attrs.dataItemType) {
      case 'image':
        return (
          <ImageItem
            key={`image-${item.id}`}
            data={item as ImageItemProps['data']}
            onDragEnd={() => {}}
            onSelect={() => { console.log('grabed'); }}
          />
        );
      case 'text':
        return (
          <TextItem
            key={`image-${item.id}`}
            data={item as TextItemProps['data']}
            transformer={tempTransfomer}
            onDragEnd={() => {}}
            onSelect={() => { console.log('grabed'); }}
          />
        );
      default:
        return null;
    }
  };

  const OpacityFilter: Filter = (imageData: ImageData) => {
    const nPixels = imageData.data.length;
    const threshold = 1;
    for (let i = 0; i < nPixels - 4; i += 4) {
      if (imageData.data[i] < threshold
        && imageData.data[i + 1] < threshold
        && imageData.data[i + 2] < threshold) {
        imageData.data[i + 3] = 0;
      }
    }
  };

  const groupRef = useRef() as MutableRefObject<Konva.Group>;

  useEffect(() => {
    if (!groupRef.current) return;

    const interval = setInterval(() => {
      if (stageData.length === 0) {
        groupRef.current.clearCache();
        return;
      }
      groupRef.current.filters([OpacityFilter]);
      groupRef.current.cache();
    }, 100);

    return () => clearInterval(interval);
  }, [groupRef, stageData]);

  useEffect(() => {
    if (!stageRef.current || !stageRef.current.container().parentElement) {
      return;
    }

    stageRef.current.container().style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/transparency_bg.png)`;
  }, [stageRef]);

  const getUserConfig = useGetUserConfig({

  }, {
    retry: false,
  });

  const {
    data: searchKey,
    error: searchKeyError,
    isLoading: searchKeyIsLoading,
  } = useGetSearchKey({
    headers: {
      Authorization: `Bearer ${auth?.getCurrentUserToken()}`,
    },
    queryParams: {
      force: false,
    },
  }, {
    retry: !!auth?.user,
    onSuccess: (() => {
      getUserConfig.refetch();
    }),
  });

  if (searchKeyError != null) {
    auth?.refreshToken();
  }

  const onExportSave = (value: Realm | null) => {
    closeModal('export-modal');
    props.onSave?.();
    if (value && !getUserConfig.data?.showInToolByDefault) {
      openModal('show-in-tool-modal');
    }
  };

  useImperativeHandle(ref, () => ({
    savePreview,
    exportPreview,
    stage: stageRef.current,
  }));

  return (
    <>
      <Box sx={{ backgroundColor: '#181818', borderRadius: '10px 10px 0px 0px' }}>
        <Typography align="center" fontSize={24}>Preview</Typography>
      </Box>
      <Stage
        ref={stageRef}
        width={WIDTH}
        height={HEIGHT}
      >
        <Layer listening={false} draggable={false}>
          <Group
            x={-((canvasWidth - (WIDTH * 2)) / 2) * 0.5}
            y={-((canvasHeight - (HEIGHT * 2)) / 2) * 0.5}
            scale={{ x: 0.5, y: 0.5 }}
          >
            <Group
              // globalCompositeOperation={"destination-out"}
              filters={[OpacityFilter]}
              ref={groupRef}
            >
              {stageData.length ? sortedStageData.map((item) => renderObject(item)) : null}
            </Group>
            {stageData.length ? renderObject(stageData[stageData.length - 1]) : null}
          </Group>
        </Layer>
      </Stage>
      <Button
        sx={{ backgroundColor: '#181818', borderRadius: 0 }}
        fullWidth
        variant="contained"
        onClick={exportPreview}
      >
        Export Image
      </Button>
      <Button
        sx={{ backgroundColor: '#FF6746', borderRadius: '0px 0px 10px 10px' }}
        fullWidth
        variant="contained"
        onClick={savePreview}
        disabled={auth?.user == null}
      >
        Save Image
      </Button>
      {auth?.user
      && !searchKeyIsLoading && searchKey?.searchKey
      && (
        <InstantSearchInterface searchKey={searchKey.searchKey} collection="realms">
          <ExportImageModal
            open={!!modals['export-modal']}
            onClose={() => { closeModal('export-modal'); }}
            onConfirm={onExportSave}
          />
          <ShowInToolModal
            open={!!modals['show-in-tool-modal']}
            onClose={() => { closeModal('show-in-tool-modal'); }}
          />
        </InstantSearchInterface>
      )}
    </>
  );
});

Preview.defaultProps = {
  onSave: () => { },
};
