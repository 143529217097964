import React, { useCallback, useState } from 'react';
import { StageData } from '../redux/currentStageData';
import useItem from './useItem';

export type StageSnapshot = {
  name: string;
  stageData: StageData[];
};

const useWorkHistory = (
  past: StageSnapshot[],
  future: StageSnapshot[],
  setPast: React.Dispatch<React.SetStateAction<StageSnapshot[]>>,
  setFuture: React.Dispatch<React.SetStateAction<StageSnapshot[]>>,
) => {
  const { alterItems } = useItem();
  const [current, setCurrent] = useState<StageSnapshot | null>(null);

  const goToPast = useCallback(() => {
    if (past.length > 0 && current) {
      const newFuture = current;
      const newStageSnapshot = past[past.length - 1];
      setPast((prev) => [...prev.slice(0, past.length - 1)]);
      setFuture((prev) => [...prev, newFuture]);
      setCurrent(newStageSnapshot);
      alterItems(newStageSnapshot.stageData);
    }
  }, [past, current, setPast, setFuture, alterItems]);

  const goToPastAt = useCallback((index: number) => {
    if (past.length > 0 && current) {
      const newFuture = current;
      const newStageSnapshot = past[index];
      setPast((prev) => [...prev.slice(0, index)]);
      setFuture((prev) => [...prev, newFuture]);
      setCurrent(newStageSnapshot);
      alterItems(newStageSnapshot.stageData);
    }
  }, [past, current, setPast, setFuture, alterItems]);

  const goToFuture = useCallback(() => {
    if (future.length > 0 && current) {
      const newPast = current;
      const newStageSnapshot = future[future.length - 1];
      setFuture((prev) => [...prev.slice(0, future.length - 1)]);
      setPast((prev) => [...prev, newPast]);
      setCurrent(newStageSnapshot);
      alterItems(newStageSnapshot.stageData);
    }
  }, [future, current, setFuture, setPast, alterItems]);

  const recordPast = useCallback(
    (newCurrent: StageSnapshot) => {
      if (newCurrent.stageData.length !== 0 && current !== null) {
        if (
          JSON.stringify(newCurrent.stageData) !== JSON.stringify(current.stageData)
        ) {
          setPast((prev) => [...prev, current]);
          setFuture([]);
        }
      }
      if (newCurrent.stageData.length !== 0) {
        setCurrent(newCurrent);
      }
    },
    [past, current, setPast, setFuture, setCurrent],
  );

  const clearHistory = () => {
    setPast([]);
    setFuture([]);
  };

  return {
    goToPast,
    goToPastAt,
    goToFuture,
    recordPast,
    clearHistory,
    setCurrent,
    current,
  };
};

export default useWorkHistory;
