/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Card, FormHelperText } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { Image } from 'mui-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../hook/use-auth';

function Copyright(props: any) {
  return (
    <Grid container alignItems="flex-end">
      <Grid item xs={6}>
        <Typography variant="body2" color="common.white" align="left" {...props}>
          {'© '}
          {`${new Date().getFullYear()} `}
          <Link color="inherit" href="#">
            Realm Worlds
          </Link>
          . All rights reserved.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="right">
          <Grid item px={2}>
            <FontAwesomeIcon color="white" icon={regular('envelope')} size="xl" />
          </Grid>
          <Grid item px={2}>
            <FontAwesomeIcon color="white" icon={brands('linkedin')} size="xl" />
          </Grid>
          <Grid item px={2}>
            <FontAwesomeIcon color="white" icon={brands('instagram')} size="xl" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function ResetPassword() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setError] = useState(false);
  const [authErrorMessage, setErrorMessage] = useState('');

  const handleReset = async (event: React.FormEvent<HTMLFormElement>) => {
    setAuthLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
    const formEmail = data.get('email');
    if (formEmail) {
      try {
        const response = await auth?.sendPasswordResetEmail(formEmail);
        if (response) {
          alert(`Reset password request sent to: ${formEmail}`);
          setAuthLoading(false);
          navigate('/');
          // console.log("reset password request sent. navigating to home");
        } else {
          setErrorMessage('Reset password request failed. Please try again.');
          setAuthLoading(false);
        }
      } catch (error: any) {
        if (error.code) {
          if (error.code === 'auth/user-not-found') {
            setErrorMessage(
              "We can't find a user with that email. Please try again.",
            );
            setError(true);
          } else {
            setErrorMessage('Something went wrong. Please try again.');
            setError(true);
          }
          setAuthLoading(false);
        }
      }
    } else {
      alert('Please enter email address.');
      setAuthLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth={false} disableGutters sx={{ backgroundColor: '#1B1B22' }}>
      <Grid container alignItems="stretch">
        <Grid item xs={6} px={3} pt={3}>
          <Typography px={3} variant="h2" align="center" color="common.white">VTT token Maker</Typography>
          <Typography mt={2} align="justify" color="common.white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</Typography>
          <Card sx={{ minWidth: 275, marginTop: 8 }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box component="form" noValidate onSubmit={handleReset} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                      />
                    </Grid>
                    {authError ? (
                      <Grid item xs={12}>
                        <FormHelperText
                          sx={() => ({
                            mx: 'auto',
                            color: 'red',
                          })}
                        >
                          {authErrorMessage}
                        </FormHelperText>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    loading={authLoading}
                  >
                    Send Reset Email
                  </LoadingButton>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Link href="/" variant="body2">
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Copyright sx={{ mt: 8 }} />
        </Grid>

        <Grid item xs={6}>
          <Image height="100vh" fit="cover" src="./images/Azamel_sorcerer_snake.png" />
        </Grid>
      </Grid>
    </Container>
  );
}
