/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-cycle */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StoreState } from './store';
import { OverrideTokenData } from '../hook/useToken';

/* eslint-disable import/no-extraneous-dependencies */
export const TOKEN_PREFIX = 'token';

export type TokenState = {
  data: OverrideTokenData<any>;
};

export const tokenInitialState: TokenState = {
  data: null,
};

export const TokenSlice = createSlice({
  name: TOKEN_PREFIX,
  initialState: tokenInitialState,
  reducers: {
    setToken: (_state, action: PayloadAction<TokenState>) => {
      const newState = {
        ...tokenInitialState,
        ...action.payload,
      };
      return newState;
    },
    clearToken: () => ({ ...tokenInitialState }),
  },
});

export const tokenSelector = (state: StoreState) => state.token;

export const TokenActions = TokenSlice.actions;
export const tokenReducer = TokenSlice.reducer;
