/* eslint-disable import/no-cycle */
import { configureStore, EntityState, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import fileMetaReducer, { FileMeta } from './fileMeta';
import stageDataReducer, { StageData, stageDataEpic } from './currentStageData';
import stageDataListReducer, { StageDataListItem } from './stageDataList';
import imageAssetListReducer, { ImageAssetListItem } from './imageAssetList';
import { tokenReducer, TokenState } from './token';
import { notificationReducer, NotificationState } from './notification';

export type StoreState = {
  notification: NotificationState;
  fileMeta: FileMeta;
  token: TokenState;
  currentStageData: EntityState<StageData['attrs']>;
  stageDataList: EntityState<StageDataListItem>;
  imageAssetList: EntityState<ImageAssetListItem>;
};

const epicMiddleware = createEpicMiddleware();

const rootEpic = combineEpics(stageDataEpic);

const configureRWStore = (preloadedState?: StoreState) => {
  const store = configureStore({
    reducer: {
      fileMeta: fileMetaReducer,
      token: tokenReducer,
      currentStageData: stageDataReducer,
      stageDataList: stageDataListReducer,
      imageAssetList: imageAssetListReducer,
      notification: notificationReducer,
    },
    middleware: getDefaultMiddleware({ serializableCheck: false }).concat(epicMiddleware),
    preloadedState,
  });
  epicMiddleware.run(rootEpic);
  return store;
};

export default configureRWStore;
