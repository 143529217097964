/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  CircularProgress,
  Grid, IconButton, Menu, MenuItem, TextField, Tooltip, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHits, useInstantSearch } from 'react-instantsearch-hooks-web';
import Image from 'mui-image';
import { Refresh, Star, StarBorder } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  fetchDeleteTokenById,
  fetchFavoriteRealmToken,
  fetchGetRealmTokenByTokenId,
  fetchUnFavoriteRealmToken,
  fetchUpdateRealmTokenFormData,
} from '../../../generated/realmWorldsApiComponents';
import ConfirmationModal from '../../modals/ConfirmationModal';
import useNotification from '../../../hook/useNotification';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  color: 'white',
  p: 4,
  outline: 'none',
  backgroundColor: 'rgba(217, 217, 217, 0.4 )',
  border: '1px solid #FFFFFF',
  borderRadius: '10px',
};

function Tokens() {
  const { displayNotification } = useNotification();
  const { refresh, status } = useInstantSearch();
  const { hits } = useHits();
  const [contextMenu, setContextMenu] = useState<{
    [key: string]: {
      mouseX: number;
      mouseY: number;
    } } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  // const [realmToDelete, setRealmToDelete] = useState<string>(null);
  const [tokenToDelete, setTokenToDelete] = useState<any | null>(null);

  const [tokenToUpdate, setTokenToUpdate] = useState<any | null>(null);
  const [openUpdateTokenModal, setOpenUpdateTokenModal] = useState<boolean>(false);
  const [newTokenName, setNewTokenName] = useState<string>('');

  const handleContextMenu = (event: React.MouseEvent, id: string) => {
    console.log('handleContextMenu: ', id);
    event.preventDefault();
    setContextMenu({
      ...contextMenu,
      [id]: { mouseX: event.clientX + 2, mouseY: event.clientY - 6 },
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const downloadToken = async (realmId: string, tokenId: string) => {
    try {
      const getTokenResposne = await fetchGetRealmTokenByTokenId({
        pathParams: {
          tokenId,
          realmId,
        },
      });
      const { name } = getTokenResposne;
      const { tokenUrl } = getTokenResposne;
      if (!tokenUrl) {
        console.error('No tokenUrl found');
        return;
      }
      // const response = await fetch(tokenUrl.replace('https://storage.googleapis.com', `${REACT_APP_URL}/tokens`));
      const response = await fetch(tokenUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = name?.endsWith('.png') ? name : `${name}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
      displayNotification({ message: `Token: ${name} downloaded successfully`, type: 'success' });
    } catch (error) {
      console.error('Failed to download file', error);
      displayNotification({ message: 'Failed to download Token', type: 'error' });
    }
    handleCloseContextMenu();
  };

  const confirmDeleteToken = (
    realmId: string,
    tokenId: string,
    ThumbnailUrl: string,
    Name: string,
  ) => {
    setTokenToDelete({
      realmId, tokenId, ThumbnailUrl, Name,
    });
    setShowConfirmDeleteModal(true);
    console.log('tokenToDelete: ', tokenToDelete);
  };

  const deleteToken = async (realmId: string, tokenId: string) => {
    try {
      await fetchDeleteTokenById({
        pathParams: {
          tokenId,
          realmId,
        },
      });
      // sleep for 2 seconds then refresh
      setLoading(true);
      setTimeout(() => {
        displayNotification({ message: 'Token deleted successfully', type: 'success' });
        refresh();
        setLoading(false);
      }, 3000);
      // setDeletedTokenIds([...deletedTokenIds, tokenId]);
    } catch (error) {
      displayNotification({ message: 'Failed to delete token', type: 'error' });
    }
    displayNotification({ message: 'Deleting Token...', type: 'info' });
    setShowConfirmDeleteModal(false);
    setTokenToDelete(null);
    handleCloseContextMenu();
  };

  const updateToken = async () => {
    setLoading(true);
    displayNotification({ message: 'Updating realm...', type: 'info' });
    try {
      console.log('newTokenName: ', newTokenName);
      const data = new FormData();
      if (newTokenName === '' && newTokenName === null) {
        displayNotification({ message: 'Failed Updating...Token name cannot be empty', type: 'error' });
        return;
      }
      data.append('TokenName', newTokenName);
      const updateRealmResponse = await fetchUpdateRealmTokenFormData({
        pathParams: {
          realmId: tokenToUpdate?.RealmId,
          tokenId: tokenToUpdate?.id,
        },
        // @ts-ignore
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('updateRealmResponse: ', updateRealmResponse);
      if (updateRealmResponse !== null) {
        setTimeout(() => {
          displayNotification({ message: 'Token updated', type: 'success' });
          refresh();
          setLoading(false);
          setOpenUpdateTokenModal(false);
        }, 3000);
      } else {
        displayNotification({ message: 'Failed to update realm', type: 'error' });
        setLoading(false);
      }
    } catch (err) {
      displayNotification({ message: 'Failed to update realm', type: 'error' });
      setLoading(false);
    }
    setNewTokenName('');
  };

  const setTokenVisibilityInTool = async (realmId: string, tokenId: string, show: boolean) => {
    setLoading(true);
    try {
      console.log('realmId: ', realmId);
      console.log('tokenId: ', tokenId);
      const data = new FormData();
      data.append('IsVisibleInTool', JSON.stringify(show));
      await fetchUpdateRealmTokenFormData({
      // @ts-ignore
        body: data,
        pathParams: {
          realmId,
          tokenId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      displayNotification({ message: 'Failed to update token', type: 'error' });
    }
    setTimeout(() => {
      displayNotification({ message: `Token is ${show ? 'now' : 'no longer'} visible in the extension`, type: 'success' });
      setLoading(false);
      refresh();
    }, 3000);
    handleCloseContextMenu();
  };

  const setTokenFavoriteValue = async (realmId: string, tokenId: string, isFavorited: boolean) => {
    setLoading(true);
    displayNotification({ message: !isFavorited ? 'Marking token as a favorite...' : 'Unfavoriting token...', type: 'info' });
    if (isFavorited) {
      const response = await fetchUnFavoriteRealmToken({
        pathParams: {
          realmId,
          tokenId,
        },
      });
      console.log('response: ', response);
    } else {
      try {
        const response = await fetchFavoriteRealmToken({
          pathParams: {
            realmId,
            tokenId,
          },
        });
        console.log('response: ', response);
      } catch (error) {
        displayNotification({ message: 'Failed to favorite token', type: 'error' });
      }
    }

    setTimeout(() => {
      displayNotification({ message: `Token is ${!isFavorited ? 'now' : 'no longer'} a favorite`, type: 'success' });
      refresh();
      setLoading(false);
    }, 3000);
  };

  // useEffect to refresh on load
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        // refresh();
      }, 1000);
    }
    return () => {
      mounted = false;
    };
  }, []);

  if (status === 'loading' || loading) {
    return (
      <Box display="flex" margin="auto" flexDirection="column" justifyContent="center" alignItems="center" sx={{ height: '30vh' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  console.log('hits: ', hits);

  return (
    <Box>
      <ConfirmationModal
        open={openUpdateTokenModal}
        setOpen={setOpenUpdateTokenModal}
        onClose={() => {}}
        onConfirm={() => {}}
      >
        <Box style={style}>
          <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              Update Token:
              {' '}
              {tokenToUpdate?.Name ?? 'Unknown'}
            </Typography>
            <Grid container spacing={4} sx={{ marginX: 4 }}>
              <Grid item xs={12} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%', marginTop: 2 }}>
                  <TextField
                    id="outlined"
                    onChange={(e) => setNewTokenName(e.target.value)}
                    label="Token Name"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    onKeyDown={(e) => { if (e.key === 'Enter') updateToken(); }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%' }}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={newTokenName === null || newTokenName === ''}
                    loading={loading}
                    onClick={updateToken}
                    sx={{ width: '100%' }}
                  >
                    Update Token
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="center" alignContent="center">
                <Box sx={{ width: '60%' }}>
                  <Button variant="contained" color="inherit" onClick={() => setOpenUpdateTokenModal(false)} sx={{ width: '100%' }}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} sx={{ marginTop: 4 }} />
          </Box>
        </Box>
      </ConfirmationModal>
      { tokenToDelete !== null ? (
        <ConfirmationModal
          open={showConfirmDeleteModal}
          onClose={() => {}}
          onConfirm={() => {}}
          setOpen={setShowConfirmDeleteModal}
        >
          <Box sx={style} display="flex" flexDirection="column" alignContent="center" margin="auto">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ fontWeight: 600 }} textAlign="center" color="info">
                  Are you sure you want to delete this token?
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 5 }}>
                <Image
                  src={tokenToDelete.ThumbnailUrl}
                  height="8vw"
                  fit="contain"
                  duration={200}
                  style={{
                    cursor: 'pointer',
                  }}
                />
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  {tokenToDelete.Name.length > 10 ? `${tokenToDelete.Name.substring(0, 10)}...` : tokenToDelete.Name}
                </Typography>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <LoadingButton loading={loading} variant="contained" color="primary" onClick={() => deleteToken(tokenToDelete.realmId, tokenToDelete.tokenId)}>
                    Delete
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button variant="contained" color="inherit" onClick={() => setShowConfirmDeleteModal(false)}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </Box>
        </ConfirmationModal>
      ) : null }
      <Box sx={{ position: 'absolute', right: 28, top: 50 }}>
        <IconButton size="large" onClick={() => refresh()}>
          <Refresh />
        </IconButton>
      </Box>
      <Grid container spacing={1} sx={{ margin: 0, padding: 0 }}>
        {/* .filter((h) => deletedTokenIds.indexOf(h.objectID) < 0) */}
        { hits.map(({
          id, RealmId, ThumbnailUrl, IsFavorited, Name, UpdatedAt, IsVisibleInTool,
        }: any) => (
          ThumbnailUrl
            ? (
              <Grid key={id} item lg={2} sm={3} xs={4} direction="column-reverse" display="flex" sx={{ padding: 0, maxWidth: '100%' }}>
                <div className={id} style={{ cursor: 'context-menu', display: 'flex', flexDirection: 'row-reverse' }}>
                  <Box sx={{ mt: -2 }}>
                    <Tooltip title={IsFavorited ? 'Click to Unfavorite' : 'Click to Favorite'} placement="top">
                      <IconButton onClick={() => setTokenFavoriteValue(RealmId, id, IsFavorited)}>
                        { IsFavorited ? <Star />
                          : <StarBorder /> }
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box display="flex" flexDirection="column" onClick={(e) => handleContextMenu(e, id)} onContextMenu={(e) => handleContextMenu(e, id)}>
                    <Image
                      src={ThumbnailUrl}
                      height="8vw"
                      fit="contain"
                      duration={200}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                    <Tooltip title={Name} placement="top">
                      <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        {Name.length > 10 ? `${Name.substring(0, 10)}...` : Name}
                      </Typography>
                    </Tooltip>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                      {'Last Updated '}
                    </Typography>
                    <Typography variant="caption" sx={{ textAlign: 'center' }}>
                      {new Date(UpdatedAt).toLocaleString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </Typography>
                  </Box>
                  <Menu
                    open={contextMenu !== null
                      && contextMenu[id] !== undefined
                      && contextMenu[id] !== null}
                    onClose={handleCloseContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                      contextMenu !== null
                      && contextMenu[id] !== undefined
                      && contextMenu[id] !== null
                        ? { top: contextMenu[id].mouseY, left: contextMenu[id].mouseX }
                        : undefined
                    }
                  >
                    <MenuItem onClick={() => downloadToken(RealmId, id)}>
                      Download
                    </MenuItem>
                    <MenuItem onClick={() => {
                      setTokenToUpdate({
                        id, ThumbnailUrl, Name, RealmId,
                      });
                      setOpenUpdateTokenModal(true);
                    }}
                    >
                      Update
                    </MenuItem>
                    <MenuItem onClick={() => confirmDeleteToken(RealmId, id, ThumbnailUrl, Name)}>
                      Delete
                    </MenuItem>
                    { IsVisibleInTool ? (
                      <MenuItem onClick={() => setTokenVisibilityInTool(RealmId, id, false)}>
                        Hide in Extension
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={() => setTokenVisibilityInTool(RealmId, id, true)}>
                        Show in Extension
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </Grid>
            ) : <></>
        ))}
      </Grid>
    </Box>
  );
}

export default React.memo(Tokens);
