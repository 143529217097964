import { useDispatch, useSelector } from 'react-redux';
import { NotificationActions, NotificationState, notificationSelector } from '../redux/notification';

const useNotification = () => {
  const dispatch = useDispatch();

  const notification = useSelector(notificationSelector);

  const displayNotification = (notificationState: NotificationState) => {
    dispatch(NotificationActions.addNotification(notificationState));
  };

  const clearNotification = () => {
    dispatch(NotificationActions.clearNotification());
  };

  return {
    notification,
    displayNotification,
    clearNotification,
  } as const;
};

export default useNotification;
