/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Box, Grid, Modal, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../hook/use-auth';
import { useUpsertUserConfig } from '../../generated/realmWorldsApiComponents';
import useModal from '../../hook/useModal';
import useNotification from '../../hook/useNotification';

type ModalSettingsProps = {
  open: boolean,
  onClose?: (value: boolean | null) => void,
};

export default function ShowInToolModal({
  open,
  onClose,
}: ModalSettingsProps) {
  const auth = useAuth();
  const { displayNotification } = useNotification();
  const {
    modalStyle,
  } = useModal();

  const upsertUserConfig = useUpsertUserConfig({
    onSuccess: (data) => {
      onClose?.(data.showInToolByDefault ?? false);
      displayNotification({ message: 'Settings Updated!', type: 'success' });
    },
    onError: () => {
      onClose?.(null);
      auth?.refreshToken();
      displayNotification({ message: 'Error Updating Settings', type: 'error' });
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => { onClose?.(null); }}
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
    >
      <Box sx={modalStyle}>
        <Grid container>
          <Grid item mb={4} xs={12} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
            <Typography variant="h4" align="center">Always Show in Extension?</Typography>
          </Grid>
          <Grid item mb={4} xs={12} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
            <Grid container px={10} py={4}>
              <Grid item xs={12} mb={2}>
                <Typography variant="h6" align="center" color="rgba(255, 255, 255, 0.5)">You can change this in Account&gt;User Settings</Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Grid container justifyContent="center" alignItems="center">
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      upsertUserConfig.mutate({ body: { showInToolByDefault: true } });
                    }}
                    loading={upsertUserConfig.isLoading}
                    sx={{
                      backgroundColor: '#FF6746', px: 10, mx: 'auto', borderRadius: '3vmin',
                    }}
                  >
                    Yes
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      upsertUserConfig.mutate({ body: { showInToolByDefault: false } });
                    }}
                    loading={upsertUserConfig.isLoading}
                    sx={{
                      backgroundColor: 'rgba(217, 217, 217, 0.2)', px: 10, mx: 'auto', borderRadius: '3vmin',
                    }}
                  >
                    No
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

ShowInToolModal.defaultProps = {
  onClose: () => { },
};
