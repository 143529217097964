/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Avatar, Checkbox, FormControlLabel, Grid, Link, styled,
} from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#1C1D24',
  color: 'white',
  boxShadow: 24,
  p: 4,
  outline: 'none',
};

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  setOpen: (v: boolean) => void,
  onConfirm: () => void
};

const GoogleButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  height: '60px',
  backgroundColor: 'white',
  borderColor: '#0063cc',
  color: '#757575',
  '&:hover': {
    borderColor: '#0062cc',
    backgroundColor: '#e8e8e8',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    borderColor: '#005cbf',
  },
});

export default function GoogleConsentModal({
  open, onClose, setOpen, onConfirm,
}: RWModalProps) {
  const handleClose = (e: Event, r: 'backdropClick' | 'escapeKeyDown') => { onClose?.(e, r); setOpen(false); };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs={12}>
              <Typography mb={3} fontSize={24} textAlign="center">Sign up</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Please Acknowledge the following to sign up</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="" color="primary" />}
                label={(
                  <p>
                    I understand and agree to Realm Worlds
                    {' '}
                    <Link underline="none" color="#FF6746" href="#">Terms of Use</Link>
                    {' '}
                    ,
                    {' '}
                    <Link underline="none" color="#FF6746" href="#">Privacy Policy</Link>
                    , and the use of additional
                    {' '}
                    <Link underline="none" color="#FF6746" href="#">functional cookies</Link>
                  </p>
                                )}
              />
            </Grid>
            <GoogleButton
              type="submit"
              fullWidth
              variant="outlined"
              startIcon={<Avatar sx={{ width: 24, height: 24 }} src="./images/google_icon.svg" />}
              sx={{ mt: 3, mb: 2 }}
              onClick={() => { onConfirm(); }}
            >
              Sign up with Google
            </GoogleButton>
            <Grid item xs={12}>
              <Typography mb={3} fontSize={12} color="#FF6746" textAlign="justify">By signing up with a third part provider, you consent to the chosen third-party setting cookies and processing your date in accordance with their respective Privacy policy </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
