import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const options = {
  shouldForwardProp: (prop: any) => prop !== 'fontColor',
};
const RWTextInput = styled(
  TextField,
  options,
)(({ fontColor }: { fontColor: string }) => ({
  backgroundColor: '#FFFFFF',
  ml: 2,
  borderRadius: 5,
  input: {
    color: fontColor ?? '#000000',
    ml: 2,
  },
  border: 'none',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
      underline: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
      underline: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
}));

export default RWTextInput;
