import { SxProps, Theme } from '@mui/material';
import { useCallback, useState } from 'react';

interface ModalState {
  [id: string]: boolean;
}

interface UseModalReturn {
  modals: ModalState;
  modalStyle: SxProps<Theme> | undefined
  openModal: (id: string) => void;
  closeModal: (id: string) => void;
}

const useModal = (initialState: ModalState = {}): UseModalReturn => {
  const [modals, setModals] = useState<ModalState>(initialState);

  const openModal = useCallback((id: string) => {
    setModals((prev) => ({ ...prev, [id]: true }));
  }, []);

  const closeModal = useCallback((id: string) => {
    setModals((prev) => ({ ...prev, [id]: false }));
  }, []);

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    color: 'white',
    p: 4,
    outline: 'none',
  };

  return {
    modals, modalStyle, openModal, closeModal,
  };
};

export default useModal;
