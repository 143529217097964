/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  AppBar,
  Card,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ThemeProvider,
  Toolbar,
  createTheme,
  styled,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import { Image } from 'mui-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  useNavigate,
  Link as RouterLink,
} from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../hook/use-auth';
import RealmWorldsService from '../services/realmWorldsService';
import GoogleConsentModal from '../components/modals/GooglePopup';

function Copyright(props: any) {
  return (
    <AppBar position="fixed" elevation={0} sx={{ backgroundColor: 'transparent', top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Grid container alignItems="flex-end">
          <Grid item xs={12} md={5} py={3} sx={{ backgroundColor: '#1B1B22' }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2" color="common.white" align="left" {...props}>
                  {'© '}
                  {`${new Date().getFullYear()} `}
                  <Link color="inherit" href="#">
                    Realm Worlds
                  </Link>
                  . All rights reserved.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="right">
                  <Grid item px={1}>
                    <Button href="https://realmworlds.net/" sx={{ backgroundColor: 'white', minWidth: 0 }} target="_blank">
                      <FontAwesomeIcon color="black" icon={solid('globe')} size="xl" />
                    </Button>
                  </Grid>
                  <Grid item px={1}>
                    <Button href="https://www.reddit.com/r/RealmWorlds" sx={{ backgroundColor: 'white', minWidth: 0 }} target="_blank">
                      <FontAwesomeIcon color="black" icon={brands('reddit')} size="xl" />
                    </Button>
                  </Grid>
                  <Grid item px={1}>
                    <Button href="#contained-buttons" sx={{ backgroundColor: 'white', minWidth: 0 }} target="_blank">
                      <FontAwesomeIcon color="black" icon={brands('youtube')} size="xl" />
                    </Button>
                  </Grid>
                  <Grid item px={1}>
                    <Button href="https://discord.gg/pndajRXRRg" sx={{ backgroundColor: 'white', minWidth: 0 }} target="_blank">
                      <FontAwesomeIcon color="black" icon={brands('discord')} size="xl" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ backgroundColor: '#1B1B22' }}>
            <Box flexGrow={1} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const GoogleButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  height: '60px',
  backgroundColor: 'white',
  borderColor: '#0063cc',
  color: '#757575',
  '&:hover': {
    borderColor: '#0062cc',
    backgroundColor: '#e8e8e8',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    borderColor: '#005cbf',
  },
});

const RMTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '&': {
    backgroundColor: 'rgba(222, 209, 176, 0.7)',
    borderRadius: '4px',
  },
});

const RMFilledInput = styled(FormControl)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '&': {
    backgroundColor: 'rgba(222, 209, 176, 0.7)',
    borderRadius: '4px',
  },
});

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#FF6746',
      main: '#FF6746',
      dark: '#262626',
      contrastText: '#fff',
    },
  },
});

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

type SignupForm = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  ofAge: boolean;
  acceptedTerms: boolean;
};

export default function SignUp() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    formState: { errors }, control, handleSubmit,
  } = useForm<SignupForm>();

  const [openGoogleModal, setOpenGoogleModal] = React.useState(false);

  const onSubmit = (form: SignupForm) => {
    console.log({
      email: form.email,
      password: form.password,
    });
    setLoading(true);
    RealmWorldsService.CreateNewUserAccount({
      email: form.email as string,
      password: form.password as string,
      displayName: `${form.firstName as string} ${form.lastName}` as string,
    }).then(async (result) => {
      if (result) {
        await auth?.signin(form.email as string, form.password as string);
        navigate('/');
      }
      setLoading(false);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" elevation={0} sx={{ backgroundColor: 'transparent' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} />
            <Typography>Already have an account?</Typography>
            <Button
              type="submit"
              variant="contained"
              sx={{ my: 3, ml: 3, px: 3 }}
              onClick={() => { navigate('/login'); }}
            >
              Login
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Container component="main" maxWidth={false} disableGutters sx={{ backgroundColor: '#1B1B22' }}>
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={5} px={5} pt={3}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <Box component="img" src="./images/rw_logo.png" width={100} />
            </Grid>
            <Typography px={3} variant="h3" align="center" color="common.white" fontWeight={600}>Sign up to Realm Worlds</Typography>
            <Typography mt={2} align="justify" color="common.white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</Typography>
            <Card sx={{
              minWidth: 275, margin: '40px', backgroundColor: '#0000', backgroundImage: 'none', boxShadow: 'none',
            }}
            >
              <CardContent sx={{ backgroundColor: '#0000' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstName"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          autoComplete="family-name"
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <GoogleButton
                          type="submit"
                          fullWidth
                          variant="outlined"
                          startIcon={<Avatar sx={{ width: 24, height: 24 }} src="./images/google_icon.svg" />}
                          sx={{ mt: 0, mb: 2 }}
                          onClick={() => setOpenGoogleModal(true)}
                        >
                          Sign up with Google
                        </GoogleButton>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="email"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <RMTextField
                              required
                              fullWidth
                              variant="filled"
                              id="email"
                              label="Email Address"
                              autoComplete="email"
                              InputProps={{ disableUnderline: true }}
                              {...field}
                              error={Boolean(errors.email)}
                              helperText={errors.email && 'email is required'}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="password"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <RMFilledInput
                              fullWidth
                              variant="filled"
                            >
                              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                              <FilledInput
                                id="outlined-adornment-password"
                                disableUnderline
                                type={showPassword ? 'text' : 'password'}
                                {...field}
                                endAdornment={(
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                            )}
                              />
                              <FormHelperText id="component-error-text" error={Boolean(errors.password)}>{errors.password && 'password is required'}</FormHelperText>
                            </RMFilledInput>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="confirmPassword"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <RMFilledInput
                              fullWidth
                              variant="filled"
                            >
                              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                              <FilledInput
                                id="outlined-adornment-password"
                                disableUnderline
                                type={showPassword ? 'text' : 'password'}
                                {...field}
                                endAdornment={(
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                            )}
                              />
                              <FormHelperText id="component-error-text" error={Boolean(errors.confirmPassword)}>{errors.confirmPassword && 'Password Confirmation is required'}</FormHelperText>
                            </RMFilledInput>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="ofAge"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <>
                              <FormControlLabel
                                control={<Checkbox {...field} value="" color="primary" />}
                                label={(
                                  <p>
                                    Confirm you are over the age of 13
                                  </p>
                                )}
                              />
                              <FormHelperText id="component-error-text" error={Boolean(errors.ofAge)}>{errors.ofAge && 'Confirmation is required'}</FormHelperText>
                            </>
                          )}
                        />
                        <Controller
                          name="acceptedTerms"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <>
                              <FormControlLabel
                                control={<Checkbox {...field} value="" color="primary" />}
                                label={(
                                  <p>
                                    I agree to the
                                    {' '}
                                    <Link color="#ffffff" href="#">Terms</Link>
                                    {' '}
                                    and
                                    {' '}
                                    <Link color="#ffffff" href="#">Conditions</Link>
                                    {' '}
                                    of Realm Worlds LLC
                                  </p>
                                )}
                              />
                              <FormHelperText id="component-error-text" error={Boolean(errors.acceptedTerms)}>{errors.acceptedTerms && 'Confirmation is required'}</FormHelperText>
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, height: 60 }}
                      loading={loading}
                    >
                      Sign Up
                    </LoadingButton>
                    <Grid container justifyContent="center" mb={4}>
                      <Grid item>
                        <LinkRouter color="#FF6746" to="/login" variant="body2">
                          <Typography align="center">Already have an account? Sign in</Typography>
                        </LinkRouter>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <Copyright />
          </Grid>

          <Grid item display={{ xs: 'none', md: 'block' }} md={7}>
            <Image height="100vh" fit="cover" src="./images/Azamel_sorcerer_snake.png" />
          </Grid>
        </Grid>
      </Container>
      <GoogleConsentModal open={openGoogleModal} onClose={() => { console.log('modal closed'); }} onConfirm={() => console.log('modal confirmed')} setOpen={setOpenGoogleModal} />
    </ThemeProvider>
  );
}
