/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Configure, Index, InstantSearch, SortBy,
} from 'react-instantsearch-hooks-web';

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { Grid, Link, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useAuth } from '../../hook/use-auth';
import { fetchGetSearchKey } from '../../generated/realmWorldsApiComponents';
import Tokens from '../Hits/Tokens/Tokens';
import Realms from '../Hits/Realms/Realms';
import SearchBar from '../search/SearchBar';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  height: '60vh',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  p: 4,
  outline: 'none',
  overflowX: 'hidden',
};

type RWModalProps = {
  open: boolean,
  onClose: () => void,
  setOpen: (v: boolean) => void,
  onConfirm: () => void
};

export default function AssetViewerModal({
  open, onClose, setOpen,
}: RWModalProps) {
  const handleClose = () => { onClose?.(); setOpen(false); };
  const auth = useAuth();
  const [collection] = React.useState('realms'); // ['realms', 'tokens'
  const [searchClient, setSearchClient] = React.useState<any>(null);
  const [searchKey, setSearchKey] = React.useState<any>(null);
  const [searchKeyError, setSearchKeyError] = React.useState<any>(null);
  const [searchKeyIsLoading, setSearchKeyIsLoading] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<any>('Tokens');
  const [selectedRealm, setSelectedRealm] = React.useState<any>(null);

  const selectedStyle = {
    paddingLeft: 1,
    paddingRight: 2,
    paddingBottom: 1,
    paddingTop: 0,
    marginTop: 0,
    marginBottom: -1,
    cursor: 'pointer',
    background: '#303030',
  };

  const unselectedStyle = {
    paddingLeft: 1,
    paddingRight: 2,
    paddingBottom: 0,
    paddingTop: 0,
    cursor: 'pointer',
    background: '#D9D9D9',
    color: '#303030',
  };

  if (searchKeyError != null) {
    console.error('searchKeyError Error: ', searchKeyError);
    auth?.refreshToken(); // this could cause a loop
  }

  const getCurrentIndex = () => {
    switch (selected) {
      case 'Tokens':
        return (
          <Index indexName="tokens">
            {/* @ts-ignore */}
            <Configure hitsPerPage={200} sort_by="UpdatedAt:asc" />
            <SortBy
              style={{ marginLeft: 8 }}
              items={[
                { value: 'tokens/sort/Name:asc', label: 'A-Z' },
                { value: 'tokens/sort/Name:desc', label: 'Z-A' },
                { value: 'tokens/sort/UpdatedAt:desc', label: 'Newest' },
                { value: 'tokens/sort/UpdatedAt:asc', label: 'Oldest' },
              ]}
            />
            <Tokens />
          </Index>
        );
      case 'Realms':
        return (
          <Index indexName={selectedRealm ? 'tokens' : 'realms'}>
            { selectedRealm ? (
              <div>
                {/* @ts-ignore */}
                <Configure hitsPerPage={200} facetFilters={[`RealmId:${selectedRealm.id}`]} />
              </div>
            ) : (
            /* @ts-ignore */
              <Configure hitsPerPage={200} />
            )}
            {/* @ts-ignore */}
            {/* <Configure hitsPerPage={200} /> */}
            <SortBy
              style={{
                marginLeft: 8, marginTop: 0,
              }}
              items={[
                { value: `${selectedRealm ? 'tokens' : 'realms'}/sort/Name:asc`, label: 'A-Z' },
                { value: `${selectedRealm ? 'tokens' : 'realms'}/sort/Name:desc`, label: 'Z-A' },
                { value: `${selectedRealm ? 'tokens' : 'realms'}/sort/UpdatedAt:desc`, label: 'Newest' },
                { value: `${selectedRealm ? 'tokens' : 'realms'}/sort/UpdatedAt:asc`, label: 'Oldest' },
              ]}
            />
            {selectedRealm !== null ? (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row">
                    <Typography variant="h6" sx={{ ml: 1, cursor: 'pointer' }} component={Link} onClick={() => { setSelectedRealm(null); }}>
                      Realms
                    </Typography>
                    <Typography variant="h6" sx={{ ml: 1 }}>
                      {`/ ${selectedRealm.Name}`}
                    </Typography>
                  </Box>
                  <Tokens />
                </Grid>
              </Grid>
            ) : (
              <Realms onSetSelectedRealm={setSelectedRealm} />)}
          </Index>
        );
      case 'Favorites':

        return (
          <Index indexName="tokens">
            <Configure
              /* @ts-ignore */
              hitsPerPage={200}
              facetsRefinements={{
                IsFavorited: ['true'],
              }}
            />
            <SortBy
              style={{ marginLeft: 8 }}
              items={[
                { value: 'tokens/sort/Name:asc', label: 'A-Z' },
                { value: 'tokens/sort/Name:desc', label: 'Z-A' },
                { value: 'tokens/sort/UpdatedAt:desc', label: 'Newest' },
                { value: 'tokens/sort/UpdatedAt:asc', label: 'Oldest' },
              ]}
            />
            <Tokens />
          </Index>
        );
      // case 'Recents':
      //   return (
      //     <Index indexName="tokens">
      //       {/* @ts-ignore */}
      //       <Configure hitsPerPage={200} />
      //       <Tokens />
      //     </Index>
      //   );
      default:
        return <></>;
    }
  };

  const updateSearchAdapter = () => {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: searchKey ?? '', // Be sure to use a Search API Key
        nodes: [
          {
            host: process.env.REACT_APP_TYPESENSE_HOST ?? '', // where xxx is the ClusterID of your Typesense Cloud cluster
            port: Number(process.env.REACT_APP_TYPESENSE_PORT) ?? 443,
            protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL ?? 'https',
          },
        ],
        cacheSearchResultsForSeconds: 0,
        useServerSideSearchCache: false, // 2 minutes
      },
      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  queryBy is required.
      collectionSpecificSearchParameters: {
        realms: {
          query_by: 'Name,Description',
          sort_by: selected === 'Recents' ? 'UpdatedAt:desc' : 'Name:asc',
        },
        tokens: {
          query_by: 'Name,Description,Notes',
          sort_by: selected === 'Recents' ? 'UpdatedAt:desc' : 'Name:asc',
        },
      },
    });
    setSearchClient(typesenseInstantsearchAdapter.searchClient);
  };

  React.useEffect(() => {
    let isMounted = true;
    if (searchKey === null && isMounted) {
      setSearchKeyIsLoading(true);
      fetchGetSearchKey({
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        queryParams: {
          force: false,
        },
      }).then((res) => {
        setSearchKey(res.searchKey);
        const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
          server: {
            apiKey: res.searchKey ?? '', // Be sure to use a Search API Key
            nodes: [
              {
                host: process.env.REACT_APP_TYPESENSE_HOST ?? '', // where xxx is the ClusterID of your Typesense Cloud cluster
                port: Number(process.env.REACT_APP_TYPESENSE_PORT) ?? 443,
                protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL ?? 'https',
              },
            ],
            cacheSearchResultsForSeconds: 0,
            useServerSideSearchCache: false, // 2 minutes
          },
          // The following parameters are directly passed to Typesense's search API endpoint.
          //  So you can pass any parameters supported by the search endpoint below.
          //  queryBy is required.
          additionalSearchParameters: {
            sort_by: selected === 'Recents' ? 'UpdatedAt:desc' : 'Name:asc',
          },
          collectionSpecificSearchParameters: {
            tokens: {
              query_by: 'Name,Description,Notes',
            },
            realms: {
              query_by: 'Name,Description',
            },
          },
        });
        setSearchClient(typesenseInstantsearchAdapter.searchClient);
        setSearchKeyIsLoading(false);
      }).catch((err) => {
        console.error('Error: ', err);
        setSearchKeyError(err);
        setSearchKeyIsLoading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [auth, searchKey, selected]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        { searchClient != null ? (
          <InstantSearch searchClient={searchClient} indexName={collection}>
            <Box sx={style} display="flex" flexDirection="column" alignContent="center" margin="auto">
              <Box sx={{ width: '60%', mb: 4 }} display="flex" flexDirection="column" alignContent="center" justifyContent="center" margin="auto">
                <SearchBar />
              </Box>
              <Box
                className="hide-scroll-bars"
                sx={{
                  background: '#303030', border: '1px solid #FFFFFF', borderRadius: 5, minWidth: '65vw', height: '65vh', margin: 'auto', overflowY: 'scroll', overflowX: 'hidden',
                }}
              >
                {/* <Index indexName={collection}>
                <h2>
                  realms
                </h2>
                <Hits hitComponent={RealmHit} />
              </Index> */}
                <Box sx={{

                }}
                >
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" sx={{ background: '#D9D9D9' }}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" width="100%" sx={{ background: '#D9D9D9' }}>
                      <Box sx={selected === 'Tokens' ? selectedStyle : unselectedStyle} onClick={() => setSelected('Tokens')}>
                        <Typography variant="h6">
                          Tokens
                        </Typography>
                      </Box>
                      {/* <Box sx={selected === 'Recents' ? selectedStyle : unselectedStyle} onClick={() => { setSelected('Recents'); updateSearchAdapter(); }}>
                        <Typography variant="h6">
                          Recents
                        </Typography>
                      </Box> */}
                      <Box sx={selected === 'Favorites' ? selectedStyle : unselectedStyle} onClick={() => { setSelected('Favorites'); updateSearchAdapter(); }}>
                        <Typography variant="h6">
                          Favorites
                        </Typography>
                      </Box>
                      <Box sx={selected === 'Realms' ? selectedStyle : unselectedStyle} onClick={() => { setSelected('Realms'); updateSearchAdapter(); }}>
                        <Typography variant="h6">
                          Realms
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      alignItems="end"
                      alignContent="center"
                      sx={{ mr: 1 }}
                    >
                      <Close sx={{ cursor: 'pointer' }} style={{ color: '#000' }} onClick={() => handleClose()} />
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: 2, paddingRight: 2 }}>
                    { !searchKeyIsLoading ? getCurrentIndex() : <></>}
                  </Box>
                </Box>
              </Box>
            </Box>
          </InstantSearch>
        ) : <></>}
      </Modal>
    </div>
  );
}
