/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreState } from './store';

export interface NotificationState {
  open?: boolean;
  type?: AlertColor;
  message?: string;
  timeout?: number | null;
}

export const notificationInitialState: NotificationState = {
  open: false,
  type: 'info',
  message: '',
  timeout: 4000,
};

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    addNotification: (_state, action: PayloadAction<NotificationState>) => ({
      ...notificationInitialState,
      ...action.payload,
      open: true,
    }),
    clearNotification: (state) => ({ ...state, open: false }),
  },
});

export const notificationSelector = (state: StoreState) => state.notification;

export const NotificationActions = NotificationSlice.actions;
export const notificationReducer = NotificationSlice.reducer;
