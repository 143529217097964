/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useConnector } from 'react-instantsearch-hooks-web';
import connectAutocomplete from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';
import type {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';
import { Hit } from 'instantsearch.js/es/types/results';
import { serialize } from 'object-to-formdata';
import { useCreateRealmTokenFormData, useGetUserConfig } from '../../generated/realmWorldsApiComponents';
import { Note, Realm } from '../../generated/realmWorldsApiSchemas';
import ConfirmationModal from './ConfirmationModal';
import useToken from '../../hook/useToken';
import useModal from '../../hook/useModal';
import CreateRealmModal from './CreateRealmModal';
import useNotification from '../../hook/useNotification';

type OptionType = {
  label: string;
  id?: string;
  inputValue?: string;
};

type CreateTokenForm = {
  realm: OptionType | null;
  tokenName: string;
};

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  onConfirm?: (realm: Realm) => void
};

export type UseAutocompleteProps = AutocompleteConnectorParams;

export function useAutocomplete(props?: UseAutocompleteProps) {
  return useConnector<AutocompleteConnectorParams, AutocompleteWidgetDescription>(
    connectAutocomplete,
    props,
  );
}

export default function ExportImageModal({
  open, onClose, onConfirm,
}: RWModalProps) {
  const [realmName, setRealmName] = React.useState<string | null>('Realm Name');
  const [showInTool, setShowInTool] = React.useState<boolean>(false);
  const [neverAskAgain, setNeverAskAgain] = React.useState<boolean>(false);
  const [handledConfirmation, setHandledConfirmation] = React.useState<boolean>(false);

  const { displayNotification } = useNotification();

  const {
    modals, modalStyle, openModal, closeModal,
  } = useModal();
  const {
    watch, formState: { errors }, control, setValue, getValues, trigger, clearErrors,
  } = useForm<CreateTokenForm>();
  const tokenName = watch('tokenName');
  const tokenRealm = watch('realm');

  const { token, updateToken } = useToken();

  // const filter = createFilterOptions<OptionType>();
  const handleClose = () => { onClose?.(new Event('close'), 'escapeKeyDown'); };

  const createRealmToken = useCreateRealmTokenFormData({
    onSuccess: (data) => {
      handleClose();
      updateToken(() => ({
        id: data.id,
        name: data.name,
        isVisibleInTool: data.isVisibleInTool,
        isPublic: data.isPublic,
        edited: false,
      }));
      onConfirm?.(data);
      displayNotification({ message: `${data.name} Created!`, type: 'success' });
    },
    onError: () => {
      handleClose();
      displayNotification({ message: 'Error creating Token', type: 'error' });
    },
  });

  const uploadToken = (exportData: CreateTokenForm) => {
    const data = {
      TokenName: exportData.tokenName ?? '',
      TokenDescription: token?.data?.description ?? '',
      Token: token?.data?.finalImageBlob as Blob ?? new Blob(),
      OriginalImage: token?.data?.droppedImageBlob as Blob ?? new Blob(),
      TokenEdits: token?.data?.edits ?? null,
      IsPublic: true,
      IsVisibleInTool: showInTool,
      Notes: [
        token?.data?.notes as Note,
      ],
    };
    const formdata = serialize(data, { indices: true });

    updateToken(() => ({
      realmId: exportData.realm?.id ?? 'error',
      edited: false,
    }));

    createRealmToken.mutate({
      // @ts-ignore
      body: formdata,
      pathParams: {
        realmId: exportData.realm?.id ?? 'error',
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const getUserConfig = useGetUserConfig({});

  useEffect(() => {
    let isMounted = true;
    if (open && isMounted) {
      getUserConfig.refetch().then((result) => {
        console.log(result.data);
        setShowInTool(result.data?.showInToolByDefault ?? false);
      });
    }
    return () => { isMounted = false; };
  }, [open]);

  const onRealmCreated = (realm: Realm | null) => {
    closeModal('create-realm-modal');
    clearErrors();
    if (realm) {
      setValue('realm', { id: realm.id, label: realm.name } as OptionType);
    } else {
      setRealmName(null);
      setValue('realm', null);
    }
  };

  const onSubmit = (data: CreateTokenForm) => {
    uploadToken(data);
    console.log('saved!!');
  };

  const handleConfirmationModalSubmit = (value: boolean) => {
    closeModal('confirm-realm-modal');
    setHandledConfirmation(value);
    onSubmit(getValues());
  };

  const attemptSubmit = React.useCallback(async () => {
    const valid = await trigger(['realm', 'tokenName']);
    if (!valid) {
      return;
    }

    if (!handledConfirmation) { // && !getUserConfig.data.hideSaveAsConfirmation
      openModal('confirm-realm-modal');
      return;
    }

    onSubmit(getValues());
  }, [handledConfirmation]);

  const date = () => {
    // creates a new Date object with the current date and time
    const curDate = new Date();
    // getMonth() returns a zero-based month, so we add 1 to get the actual month number
    const month = curDate.getMonth() + 1;
    const day = curDate.getDate();
    // getFullYear() returns the full year, so we use slice() to get the last two digits
    const year = curDate.getFullYear().toString().slice(-2);

    const hours = curDate.getHours();
    const minutes = curDate.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am'; // determine whether it's morning or afternoon/evening
    const formattedHours = hours > 12 ? hours - 12 : hours; // convert 24-hour time to 12-hour time
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // add a leading zero if minutes is less than 10

    return `${month}.${day}.${year} - ${formattedHours}:${formattedMinutes}${period}`;
  };

  const { indices, refine } = useAutocomplete();
  const [hits, setHits] = React.useState<Hit[]>([]);

  useEffect(() => {
    setValue('tokenName', token.data?.name ?? 'New Realm');
  }, [open]);

  useEffect(() => {
    setHits(indices[0].hits);
  }, [indices, hits]);

  return (
    <Box sx={{ mt: 3 }}>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
      >
        <Box sx={modalStyle}>

          <Grid container p={2}>
            <Grid item mb={4} xs={12} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
              <Typography variant="h4" align="center">Save As</Typography>
            </Grid>
            <Grid item p={2} xs={12} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
              >
                <Box component="img" width={120} height={120} src={token?.data?.finalImageSrc} />
              </Grid>
              <Grid container px={10} mt={2}>
                <Grid item xs={12} mt={2}>
                  <Controller
                    name="realm"
                    control={control}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        id="realm"
                        {...field}
                        disablePortal
                        freeSolo
                        handleHomeEndKeys
                        clearOnBlur
                        options={hits.map((curRealm) => (
                          {
                            label: curRealm.Name,
                            id: curRealm.id,
                          } as OptionType)) ?? []}
                        onInputChange={(event: any, newValue: string) => {
                          refine(newValue);
                        }}
                        onChange={(_event, newValue) => {
                          if (typeof newValue === 'string') {
                            // timeout to avoid instant validation of the dialog's form.
                            field.onChange({ label: '' });
                            setRealmName(newValue);
                            setTimeout(() => {
                              openModal('create-realm-modal');
                            });
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            field.onChange({ label: '' });
                            setRealmName(newValue.inputValue);
                            setTimeout(() => {
                              openModal('create-realm-modal');
                            });
                          } else {
                            field.onChange(newValue);
                            setRealmName(newValue?.label ?? 'Realm Name');
                          }
                        }}
                        fullWidth
                        blurOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Realms"
                            variant="outlined"
                            placeholder="Select A Realm"
                            error={Boolean(errors.realm)}
                            helperText={errors.realm && 'Realm is required'}
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = options;

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options
                            .some((option) => inputValue === option.label);
                          if (inputValue !== '' && !isExisting) {
                            filtered.unshift({
                              inputValue,
                              label: `Create "${inputValue}" Realm`,
                            });
                          }

                          return filtered;
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Controller
                    name="tokenName"
                    control={control}
                    defaultValue={token?.data?.name ?? ''}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        label="Name"
                        fullWidth
                        {...field}
                        error={Boolean(errors.tokenName)}
                        helperText={errors.tokenName && 'Token name is required'}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Grid container justifyContent="center" alignItems="center">
                    <FormControlLabel
                      control={(
                        <Checkbox
                          value=""
                          color="primary"
                          checked={showInTool}
                          onChange={(e) => setShowInTool(e.target.checked)}
                        />
                      )}
                      label={(
                        <p>
                          Show in Extension
                        </p>
                          )}
                    />
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center">
                    <LoadingButton
                      onClick={attemptSubmit}
                      loading={createRealmToken.isLoading}
                      sx={{
                        backgroundColor: '#FF6746', px: 10, mx: 'auto', borderRadius: '3vmin',
                      }}
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CreateRealmModal
            realmName={realmName ?? 'New Realm'}
            open={!!modals['create-realm-modal']}
            onClose={onRealmCreated}
          />
          <ConfirmationModal
            open={!!modals['confirm-realm-modal']}
            onClose={() => { closeModal('confirm-realm-modal'); }}
            onConfirm={() => {}}
          >
            <Grid container p={2} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
              <Grid item mb={4} xs={12}>
                <Typography variant="h4" align="center">Save as?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container p={2} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
                  <Grid item xs={12} sx={{ borderBottom: 3, borderbottomColor: 'white' }}>
                    <Typography variant="h4" align="center">{tokenName}</Typography>
                  </Grid>
                  <Grid item mb={4} mt={1} xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography fontSize={12} align="left">{tokenRealm && tokenRealm.label}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontSize={12} align="right">{date()}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography fontSize={18} align="left" />
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Grid container justifyContent="center" alignItems="center">
                  <LoadingButton
                    onClick={() => { handleConfirmationModalSubmit(true); }}
                    loading={createRealmToken.isLoading}
                    sx={{
                      backgroundColor: '#FF6746', px: 10, mx: 'auto', borderRadius: '3vmin',
                    }}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Grid container justifyContent="center" alignItems="center">
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value=""
                        color="primary"
                        onChange={(e) => setNeverAskAgain(e.target.checked)}
                      />
                      )}
                    value={neverAskAgain}
                    label={(
                      <p>
                        NEVER ASK AGAIN*
                      </p>
                          )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ConfirmationModal>
        </Box>

      </Modal>

    </Box>
  );
}
