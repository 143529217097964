/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from "@tanstack/react-query";
import {
  useRealmWorldsApiContext,
  RealmWorldsApiContext,
} from "./realmWorldsApiContext";
import type * as Fetcher from "./realmWorldsApiFetcher";
import { realmWorldsApiFetch } from "./realmWorldsApiFetcher";
import type * as Schemas from "./realmWorldsApiSchemas";

export type CreateRealmForAuthenticatedUserError =
  Fetcher.ErrorWrapper<undefined>;

export type CreateRealmForAuthenticatedUserVariables = {
  body?: Schemas.RealmWorldsNewRealmRequest;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateRealmForAuthenticatedUser = (
  variables: CreateRealmForAuthenticatedUserVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.Realm,
    CreateRealmForAuthenticatedUserError,
    Schemas.RealmWorldsNewRealmRequest,
    {},
    {},
    {}
  >({ url: "/Realms/User/Me", method: "post", ...variables, signal });

export const useCreateRealmForAuthenticatedUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Realm,
      CreateRealmForAuthenticatedUserError,
      CreateRealmForAuthenticatedUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.Realm,
    CreateRealmForAuthenticatedUserError,
    CreateRealmForAuthenticatedUserVariables
  >(
    (variables: CreateRealmForAuthenticatedUserVariables) =>
      fetchCreateRealmForAuthenticatedUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetMyRealmsQueryParams = {
  lastTokenId?: string;
  /**
   * @format int32
   * @default 10
   */
  pageSize?: number;
  /**
   * @default CreatedAt
   */
  orderBy?: string;
  /**
   * @default true
   */
  isPublic?: boolean;
  /**
   * @default true
   */
  isVisibleInTool?: boolean;
  /**
   * @default true
   */
  isDirectionAsc?: boolean;
};

export type GetMyRealmsError = Fetcher.ErrorWrapper<undefined>;

export type GetMyRealmsResponse = Schemas.Realm[];

export type GetMyRealmsVariables = {
  queryParams?: GetMyRealmsQueryParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGetMyRealms = (
  variables: GetMyRealmsVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    GetMyRealmsResponse,
    GetMyRealmsError,
    undefined,
    {},
    GetMyRealmsQueryParams,
    {}
  >({ url: "/Realms/User/Me", method: "get", ...variables, signal });

export const useGetMyRealms = <TData = GetMyRealmsResponse>(
  variables: GetMyRealmsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetMyRealmsResponse, GetMyRealmsError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<GetMyRealmsResponse, GetMyRealmsError, TData>(
    queryKeyFn({
      path: "/Realms/User/Me",
      operationId: "getMyRealms",
      variables,
    }),
    ({ signal }) =>
      fetchGetMyRealms({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type UpdateRealmForAuthenticatedUserPathParams = {
  realmId: string;
};

export type UpdateRealmForAuthenticatedUserError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdateRealmForAuthenticatedUserVariables = {
  body?: Schemas.RealmUpdateRequest;
  pathParams: UpdateRealmForAuthenticatedUserPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUpdateRealmForAuthenticatedUser = (
  variables: UpdateRealmForAuthenticatedUserVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.Realm,
    UpdateRealmForAuthenticatedUserError,
    Schemas.RealmUpdateRequest,
    {},
    {},
    UpdateRealmForAuthenticatedUserPathParams
  >({ url: "/Realms/{realmId}", method: "put", ...variables, signal });

export const useUpdateRealmForAuthenticatedUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Realm,
      UpdateRealmForAuthenticatedUserError,
      UpdateRealmForAuthenticatedUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.Realm,
    UpdateRealmForAuthenticatedUserError,
    UpdateRealmForAuthenticatedUserVariables
  >(
    (variables: UpdateRealmForAuthenticatedUserVariables) =>
      fetchUpdateRealmForAuthenticatedUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetRealmByIdPathParams = {
  realmId: string;
};

export type GetRealmByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetRealmByIdVariables = {
  pathParams: GetRealmByIdPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGetRealmById = (
  variables: GetRealmByIdVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.Realm,
    GetRealmByIdError,
    undefined,
    {},
    {},
    GetRealmByIdPathParams
  >({ url: "/Realms/{realmId}", method: "get", ...variables, signal });

export const useGetRealmById = <TData = Schemas.Realm>(
  variables: GetRealmByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Realm, GetRealmByIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<Schemas.Realm, GetRealmByIdError, TData>(
    queryKeyFn({
      path: "/Realms/{realmId}",
      operationId: "getRealmById",
      variables,
    }),
    ({ signal }) =>
      fetchGetRealmById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type DeleteRealmByIdPathParams = {
  realmId: string;
};

export type DeleteRealmByIdError = Fetcher.ErrorWrapper<undefined>;

export type DeleteRealmByIdVariables = {
  pathParams: DeleteRealmByIdPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchDeleteRealmById = (
  variables: DeleteRealmByIdVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    boolean,
    DeleteRealmByIdError,
    undefined,
    {},
    {},
    DeleteRealmByIdPathParams
  >({ url: "/Realms/{realmId}", method: "delete", ...variables, signal });

export const useDeleteRealmById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      boolean,
      DeleteRealmByIdError,
      DeleteRealmByIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    boolean,
    DeleteRealmByIdError,
    DeleteRealmByIdVariables
  >(
    (variables: DeleteRealmByIdVariables) =>
      fetchDeleteRealmById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetRealmTokensPathParams = {
  realmId: string;
};

export type GetRealmTokensQueryParams = {
  lastTokenId?: string;
  isPublic?: boolean;
  isVisibleInTool?: boolean;
  /**
   * @format int32
   * @default 10
   */
  pageSize?: number;
  /**
   * Name, CreatedAt, or UpdatedAt
   *
   * @default CreatedAt
   */
  orderBy?: string;
  /**
   * @default true
   */
  isDirectionAsc?: boolean;
};

export type GetRealmTokensError = Fetcher.ErrorWrapper<undefined>;

export type GetRealmTokensResponse = Schemas.Token[];

export type GetRealmTokensVariables = {
  pathParams: GetRealmTokensPathParams;
  queryParams?: GetRealmTokensQueryParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGetRealmTokens = (
  variables: GetRealmTokensVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    GetRealmTokensResponse,
    GetRealmTokensError,
    undefined,
    {},
    GetRealmTokensQueryParams,
    GetRealmTokensPathParams
  >({ url: "/Realms/{realmId}/Tokens", method: "get", ...variables, signal });

export const useGetRealmTokens = <TData = GetRealmTokensResponse>(
  variables: GetRealmTokensVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetRealmTokensResponse,
      GetRealmTokensError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<
    GetRealmTokensResponse,
    GetRealmTokensError,
    TData
  >(
    queryKeyFn({
      path: "/Realms/{realmId}/Tokens",
      operationId: "getRealmTokens",
      variables,
    }),
    ({ signal }) =>
      fetchGetRealmTokens({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CreateRealmTokenFormDataPathParams = {
  realmId: string;
};

export type CreateRealmTokenFormDataError = Fetcher.ErrorWrapper<undefined>;

export type CreateRealmTokenFormDataRequestBody = {
  /**
   * Custom Token Name
   */
  TokenName?: string;
  /**
   * Custom Token Description
   */
  TokenDescription?: string;
  /**
   * Custom Token Edits that created the Token
   */
  TokenEdits?: Schemas.TokenEdits[];
  /**
   * Orignal Image that was uploaded to create the token
   *
   * @format binary
   */
  OriginalImage?: Blob;
  /**
   * Final Image that is the Token
   *
   * @format binary
   */
  Token?: Blob;
  /**
   * Is a Public Realm
   */
  IsPublic?: boolean;
  /**
   * Is the Realm Visible in the Tool
   */
  IsVisibleInTool?: boolean;
  /**
   * Is the Realm Visible in the Tool
   */
  IsFavorited?: boolean;
  /**
   * List of Notes for a Token
   */
  Notes?: Schemas.Note[];
};

export type CreateRealmTokenFormDataVariables = {
  body?: CreateRealmTokenFormDataRequestBody;
  pathParams: CreateRealmTokenFormDataPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateRealmTokenFormData = (
  variables: CreateRealmTokenFormDataVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.Token,
    CreateRealmTokenFormDataError,
    CreateRealmTokenFormDataRequestBody,
    {},
    {},
    CreateRealmTokenFormDataPathParams
  >({ url: "/Realms/{realmId}/Tokens", method: "post", ...variables, signal });

export const useCreateRealmTokenFormData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Token,
      CreateRealmTokenFormDataError,
      CreateRealmTokenFormDataVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.Token,
    CreateRealmTokenFormDataError,
    CreateRealmTokenFormDataVariables
  >(
    (variables: CreateRealmTokenFormDataVariables) =>
      fetchCreateRealmTokenFormData({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetRealmTokenByTokenIdPathParams = {
  /**
   * Token ID
   */
  tokenId: string;
  /**
   * Realm that the Token exist in
   */
  realmId: string;
};

export type GetRealmTokenByTokenIdError = Fetcher.ErrorWrapper<undefined>;

export type GetRealmTokenByTokenIdVariables = {
  pathParams: GetRealmTokenByTokenIdPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGetRealmTokenByTokenId = (
  variables: GetRealmTokenByTokenIdVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.Token,
    GetRealmTokenByTokenIdError,
    undefined,
    {},
    {},
    GetRealmTokenByTokenIdPathParams
  >({
    url: "/Realms/{realmId}/Tokens/{tokenId}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetRealmTokenByTokenId = <TData = Schemas.Token>(
  variables: GetRealmTokenByTokenIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Token,
      GetRealmTokenByTokenIdError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<Schemas.Token, GetRealmTokenByTokenIdError, TData>(
    queryKeyFn({
      path: "/Realms/{realmId}/Tokens/{tokenId}",
      operationId: "getRealmTokenByTokenId",
      variables,
    }),
    ({ signal }) =>
      fetchGetRealmTokenByTokenId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type DeleteTokenByIdPathParams = {
  realmId: string;
  tokenId: string;
};

export type DeleteTokenByIdError = Fetcher.ErrorWrapper<undefined>;

export type DeleteTokenByIdVariables = {
  pathParams: DeleteTokenByIdPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchDeleteTokenById = (
  variables: DeleteTokenByIdVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    boolean,
    DeleteTokenByIdError,
    undefined,
    {},
    {},
    DeleteTokenByIdPathParams
  >({
    url: "/Realms/{realmId}/Tokens/{tokenId}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteTokenById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      boolean,
      DeleteTokenByIdError,
      DeleteTokenByIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    boolean,
    DeleteTokenByIdError,
    DeleteTokenByIdVariables
  >(
    (variables: DeleteTokenByIdVariables) =>
      fetchDeleteTokenById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UpdateRealmTokenFormDataPathParams = {
  realmId: string;
  tokenId: string;
};

export type UpdateRealmTokenFormDataError = Fetcher.ErrorWrapper<undefined>;

export type UpdateRealmTokenFormDataRequestBody = {
  /**
   * Custom Token Name
   */
  TokenName?: string;
  /**
   * Custom Token Description
   */
  TokenDescription?: string;
  /**
   * Custom Token Edits that created the Token
   */
  TokenEdits?: Schemas.TokenEdits[];
  /**
   * Orignal Image that was uploaded to create the token
   *
   * @format binary
   */
  OriginalImage?: Blob;
  /**
   * Final Image that is the Token
   *
   * @format binary
   */
  Token?: Blob;
  /**
   * Is a Public Realm
   */
  IsPublic?: boolean;
  /**
   * Is the Realm Visible in the Tool
   */
  IsVisibleInTool?: boolean;
  /**
   * Is the Realm Visible in the Tool
   */
  IsFavorited?: boolean;
  /**
   * List of Notes for a Token
   */
  Notes?: Schemas.Note[];
};

export type UpdateRealmTokenFormDataVariables = {
  body?: UpdateRealmTokenFormDataRequestBody;
  pathParams: UpdateRealmTokenFormDataPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUpdateRealmTokenFormData = (
  variables: UpdateRealmTokenFormDataVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.Token,
    UpdateRealmTokenFormDataError,
    UpdateRealmTokenFormDataRequestBody,
    {},
    {},
    UpdateRealmTokenFormDataPathParams
  >({
    url: "/Realms/{realmId}/Tokens/{tokenId}",
    method: "put",
    ...variables,
    signal,
  });

export const useUpdateRealmTokenFormData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Token,
      UpdateRealmTokenFormDataError,
      UpdateRealmTokenFormDataVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.Token,
    UpdateRealmTokenFormDataError,
    UpdateRealmTokenFormDataVariables
  >(
    (variables: UpdateRealmTokenFormDataVariables) =>
      fetchUpdateRealmTokenFormData({ ...fetcherOptions, ...variables }),
    options
  );
};

export type AddNotesToTokenPathParams = {
  realmId: string;
  tokenId: string;
};

export type AddNotesToTokenError = Fetcher.ErrorWrapper<undefined>;

export type AddNotesToTokenRequestBody = Schemas.Note[];

export type AddNotesToTokenVariables = {
  body?: AddNotesToTokenRequestBody;
  pathParams: AddNotesToTokenPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchAddNotesToToken = (
  variables: AddNotesToTokenVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.Token,
    AddNotesToTokenError,
    AddNotesToTokenRequestBody,
    {},
    {},
    AddNotesToTokenPathParams
  >({
    url: "/Realms/{realmId}/Tokens/{tokenId}/Note",
    method: "post",
    ...variables,
    signal,
  });

export const useAddNotesToToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Token,
      AddNotesToTokenError,
      AddNotesToTokenVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.Token,
    AddNotesToTokenError,
    AddNotesToTokenVariables
  >(
    (variables: AddNotesToTokenVariables) =>
      fetchAddNotesToToken({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FavoriteRealmTokenPathParams = {
  realmId: string;
  tokenId: string;
};

export type FavoriteRealmTokenError = Fetcher.ErrorWrapper<undefined>;

export type FavoriteRealmTokenVariables = {
  pathParams: FavoriteRealmTokenPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchFavoriteRealmToken = (
  variables: FavoriteRealmTokenVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    boolean,
    FavoriteRealmTokenError,
    undefined,
    {},
    {},
    FavoriteRealmTokenPathParams
  >({
    url: "/Realms/{realmId}/Tokens/{tokenId}/Favorite",
    method: "put",
    ...variables,
    signal,
  });

export const useFavoriteRealmToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      boolean,
      FavoriteRealmTokenError,
      FavoriteRealmTokenVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    boolean,
    FavoriteRealmTokenError,
    FavoriteRealmTokenVariables
  >(
    (variables: FavoriteRealmTokenVariables) =>
      fetchFavoriteRealmToken({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UnFavoriteRealmTokenPathParams = {
  realmId: string;
  tokenId: string;
};

export type UnFavoriteRealmTokenError = Fetcher.ErrorWrapper<undefined>;

export type UnFavoriteRealmTokenVariables = {
  pathParams: UnFavoriteRealmTokenPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUnFavoriteRealmToken = (
  variables: UnFavoriteRealmTokenVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    boolean,
    UnFavoriteRealmTokenError,
    undefined,
    {},
    {},
    UnFavoriteRealmTokenPathParams
  >({
    url: "/Realms/{realmId}/Tokens/{tokenId}/UnFavorite",
    method: "put",
    ...variables,
    signal,
  });

export const useUnFavoriteRealmToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      boolean,
      UnFavoriteRealmTokenError,
      UnFavoriteRealmTokenVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    boolean,
    UnFavoriteRealmTokenError,
    UnFavoriteRealmTokenVariables
  >(
    (variables: UnFavoriteRealmTokenVariables) =>
      fetchUnFavoriteRealmToken({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAuthenticatedUserFavoriteRealmsQueryParams = {
  lastTokenId?: string;
  /**
   * @format int32
   * @default 10
   */
  pageSize?: number;
  /**
   * Name, CreatedAt, or UpdatedAt
   *
   * @default CreatedAt
   */
  orderBy?: string;
  /**
   * @default true
   */
  isDirectionAsc?: boolean;
};

export type GetAuthenticatedUserFavoriteRealmsError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAuthenticatedUserFavoriteRealmsResponse = Schemas.Realm[];

export type GetAuthenticatedUserFavoriteRealmsVariables = {
  queryParams?: GetAuthenticatedUserFavoriteRealmsQueryParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGetAuthenticatedUserFavoriteRealms = (
  variables: GetAuthenticatedUserFavoriteRealmsVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    GetAuthenticatedUserFavoriteRealmsResponse,
    GetAuthenticatedUserFavoriteRealmsError,
    undefined,
    {},
    GetAuthenticatedUserFavoriteRealmsQueryParams,
    {}
  >({ url: "/Realms/User/Me/Favorites", method: "get", ...variables, signal });

export const useGetAuthenticatedUserFavoriteRealms = <
  TData = GetAuthenticatedUserFavoriteRealmsResponse
>(
  variables: GetAuthenticatedUserFavoriteRealmsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAuthenticatedUserFavoriteRealmsResponse,
      GetAuthenticatedUserFavoriteRealmsError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<
    GetAuthenticatedUserFavoriteRealmsResponse,
    GetAuthenticatedUserFavoriteRealmsError,
    TData
  >(
    queryKeyFn({
      path: "/Realms/User/Me/Favorites",
      operationId: "getAuthenticatedUserFavoriteRealms",
      variables,
    }),
    ({ signal }) =>
      fetchGetAuthenticatedUserFavoriteRealms(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type GetAuthenticatedUserFavoriteTokensQueryParams = {
  lastTokenId?: string;
  /**
   * @format int32
   * @default 10
   */
  pageSize?: number;
  /**
   * Name, CreatedAt, or UpdatedAt
   *
   * @default CreatedAt
   */
  orderBy?: string;
  /**
   * @default true
   */
  isDirectionAsc?: boolean;
};

export type GetAuthenticatedUserFavoriteTokensError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAuthenticatedUserFavoriteTokensResponse = Schemas.Token[];

export type GetAuthenticatedUserFavoriteTokensVariables = {
  queryParams?: GetAuthenticatedUserFavoriteTokensQueryParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGetAuthenticatedUserFavoriteTokens = (
  variables: GetAuthenticatedUserFavoriteTokensVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    GetAuthenticatedUserFavoriteTokensResponse,
    GetAuthenticatedUserFavoriteTokensError,
    undefined,
    {},
    GetAuthenticatedUserFavoriteTokensQueryParams,
    {}
  >({
    url: "/Realms/User/Me/Tokens/Favorites",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAuthenticatedUserFavoriteTokens = <
  TData = GetAuthenticatedUserFavoriteTokensResponse
>(
  variables: GetAuthenticatedUserFavoriteTokensVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAuthenticatedUserFavoriteTokensResponse,
      GetAuthenticatedUserFavoriteTokensError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<
    GetAuthenticatedUserFavoriteTokensResponse,
    GetAuthenticatedUserFavoriteTokensError,
    TData
  >(
    queryKeyFn({
      path: "/Realms/User/Me/Tokens/Favorites",
      operationId: "getAuthenticatedUserFavoriteTokens",
      variables,
    }),
    ({ signal }) =>
      fetchGetAuthenticatedUserFavoriteTokens(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CreateRealmWorldsUserError = Fetcher.ErrorWrapper<undefined>;

export type CreateRealmWorldsUserVariables = {
  body?: Schemas.RealmWorldsNewUserRequest;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateRealmWorldsUser = (
  variables: CreateRealmWorldsUserVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.RealmWorldsUserResponse,
    CreateRealmWorldsUserError,
    Schemas.RealmWorldsNewUserRequest,
    {},
    {},
    {}
  >({ url: "/Users/Signup", method: "post", ...variables, signal });

export const useCreateRealmWorldsUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RealmWorldsUserResponse,
      CreateRealmWorldsUserError,
      CreateRealmWorldsUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.RealmWorldsUserResponse,
    CreateRealmWorldsUserError,
    CreateRealmWorldsUserVariables
  >(
    (variables: CreateRealmWorldsUserVariables) =>
      fetchCreateRealmWorldsUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UpgradeRealmWorldUserSubscriptionError =
  Fetcher.ErrorWrapper<undefined>;

export type UpgradeRealmWorldUserSubscriptionVariables = {
  body?: Schemas.RealmWorldsUserSubscriptionUpgradeRequest;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUpgradeRealmWorldUserSubscription = (
  variables: UpgradeRealmWorldUserSubscriptionVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.SmartAISSGPAdminUserSubscription,
    UpgradeRealmWorldUserSubscriptionError,
    Schemas.RealmWorldsUserSubscriptionUpgradeRequest,
    {},
    {},
    {}
  >({
    url: "/Users/Subscription/Upgrade",
    method: "put",
    ...variables,
    signal,
  });

export const useUpgradeRealmWorldUserSubscription = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SmartAISSGPAdminUserSubscription,
      UpgradeRealmWorldUserSubscriptionError,
      UpgradeRealmWorldUserSubscriptionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.SmartAISSGPAdminUserSubscription,
    UpgradeRealmWorldUserSubscriptionError,
    UpgradeRealmWorldUserSubscriptionVariables
  >(
    (variables: UpgradeRealmWorldUserSubscriptionVariables) =>
      fetchUpgradeRealmWorldUserSubscription({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type GetSearchKeyQueryParams = {
  /**
   * Force a new search key to be generated
   *
   * @default false
   */
  force?: boolean;
};

export type GetSearchKeyError = Fetcher.ErrorWrapper<undefined>;

export type GetSearchKeyVariables = {
  queryParams?: GetSearchKeyQueryParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGetSearchKey = (
  variables: GetSearchKeyVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.UserSearchKeyResponse,
    GetSearchKeyError,
    undefined,
    {},
    GetSearchKeyQueryParams,
    {}
  >({ url: "/Users/SearchKey", method: "get", ...variables, signal });

export const useGetSearchKey = <TData = Schemas.UserSearchKeyResponse>(
  variables: GetSearchKeyVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UserSearchKeyResponse,
      GetSearchKeyError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<
    Schemas.UserSearchKeyResponse,
    GetSearchKeyError,
    TData
  >(
    queryKeyFn({
      path: "/Users/SearchKey",
      operationId: "getSearchKey",
      variables,
    }),
    ({ signal }) =>
      fetchGetSearchKey({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type GetUserConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetUserConfigVariables = RealmWorldsApiContext["fetcherOptions"];

export const fetchGetUserConfig = (
  variables: GetUserConfigVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.UserConfiguration,
    GetUserConfigError,
    undefined,
    {},
    {},
    {}
  >({ url: "/Users/Config", method: "get", ...variables, signal });

export const useGetUserConfig = <TData = Schemas.UserConfiguration>(
  variables: GetUserConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UserConfiguration,
      GetUserConfigError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<
    Schemas.UserConfiguration,
    GetUserConfigError,
    TData
  >(
    queryKeyFn({
      path: "/Users/Config",
      operationId: "getUserConfig",
      variables,
    }),
    ({ signal }) =>
      fetchGetUserConfig({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type UpsertUserConfigError = Fetcher.ErrorWrapper<undefined>;

export type UpsertUserConfigVariables = {
  body?: Schemas.UserConfigurationUpsert;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUpsertUserConfig = (
  variables: UpsertUserConfigVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.UserConfiguration,
    UpsertUserConfigError,
    Schemas.UserConfigurationUpsert,
    {},
    {},
    {}
  >({ url: "/Users/Config", method: "put", ...variables, signal });

export const useUpsertUserConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserConfiguration,
      UpsertUserConfigError,
      UpsertUserConfigVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.UserConfiguration,
    UpsertUserConfigError,
    UpsertUserConfigVariables
  >(
    (variables: UpsertUserConfigVariables) =>
      fetchUpsertUserConfig({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ResetUserConfigError = Fetcher.ErrorWrapper<undefined>;

export type ResetUserConfigVariables = RealmWorldsApiContext["fetcherOptions"];

export const fetchResetUserConfig = (
  variables: ResetUserConfigVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.UserConfiguration,
    ResetUserConfigError,
    undefined,
    {},
    {},
    {}
  >({ url: "/Users/Config/Reset", method: "put", ...variables, signal });

export const useResetUserConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserConfiguration,
      ResetUserConfigError,
      ResetUserConfigVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.UserConfiguration,
    ResetUserConfigError,
    ResetUserConfigVariables
  >(
    (variables: ResetUserConfigVariables) =>
      fetchResetUserConfig({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetUserProfileError = Fetcher.ErrorWrapper<undefined>;

export type GetUserProfileVariables = RealmWorldsApiContext["fetcherOptions"];

export const fetchGetUserProfile = (
  variables: GetUserProfileVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<Schemas.User, GetUserProfileError, undefined, {}, {}, {}>(
    { url: "/Users/Profile", method: "get", ...variables, signal }
  );

export const useGetUserProfile = <TData = Schemas.User>(
  variables: GetUserProfileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.User, GetUserProfileError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<Schemas.User, GetUserProfileError, TData>(
    queryKeyFn({
      path: "/Users/Profile",
      operationId: "getUserProfile",
      variables,
    }),
    ({ signal }) =>
      fetchGetUserProfile({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type UpdateUserProfileError = Fetcher.ErrorWrapper<undefined>;

export type UpdateUserProfileVariables = {
  body?: Schemas.User;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUpdateUserProfile = (
  variables: UpdateUserProfileVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.User,
    UpdateUserProfileError,
    Schemas.User,
    {},
    {},
    {}
  >({ url: "/Users/Profile", method: "put", ...variables, signal });

export const useUpdateUserProfile = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.User,
      UpdateUserProfileError,
      UpdateUserProfileVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.User,
    UpdateUserProfileError,
    UpdateUserProfileVariables
  >(
    (variables: UpdateUserProfileVariables) =>
      fetchUpdateUserProfile({ ...fetcherOptions, ...variables }),
    options
  );
};

export type QueryOperation =
  | {
      path: "/Realms/User/Me";
      operationId: "getMyRealms";
      variables: GetMyRealmsVariables;
    }
  | {
      path: "/Realms/{realmId}";
      operationId: "getRealmById";
      variables: GetRealmByIdVariables;
    }
  | {
      path: "/Realms/{realmId}/Tokens";
      operationId: "getRealmTokens";
      variables: GetRealmTokensVariables;
    }
  | {
      path: "/Realms/{realmId}/Tokens/{tokenId}";
      operationId: "getRealmTokenByTokenId";
      variables: GetRealmTokenByTokenIdVariables;
    }
  | {
      path: "/Realms/User/Me/Favorites";
      operationId: "getAuthenticatedUserFavoriteRealms";
      variables: GetAuthenticatedUserFavoriteRealmsVariables;
    }
  | {
      path: "/Realms/User/Me/Tokens/Favorites";
      operationId: "getAuthenticatedUserFavoriteTokens";
      variables: GetAuthenticatedUserFavoriteTokensVariables;
    }
  | {
      path: "/Users/SearchKey";
      operationId: "getSearchKey";
      variables: GetSearchKeyVariables;
    }
  | {
      path: "/Users/Config";
      operationId: "getUserConfig";
      variables: GetUserConfigVariables;
    }
  | {
      path: "/Users/Profile";
      operationId: "getUserProfile";
      variables: GetUserProfileVariables;
    };
