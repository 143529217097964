import { Search } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks-web';

const searchStyleInput = {
  background: 'rgba(217, 217, 217, 0.2)',
  border: '1px solid #FFFFFF',
  borderRadius: 15,
  color: '#FFFFFF',
  height: 35,
  fontSize: 30,
  width: '100%',
  textAlign: 'center' as 'center',
  placeholder: {
    textAlign: 'center' as 'center',
  },
  before: {
    content: <Search />,
  },
};

function SearchBar(props: UseSearchBoxProps) {
  const { query, refine } = useSearchBox(props);

  return (
    <Box>
      <Search sx={{ marginBottom: -4.5, marginLeft: 1 }} />
      <input
        type="text"
        value={query}
        onChange={(event) => refine(event.currentTarget.value)}
        placeholder="Search"
        style={searchStyleInput}
      />
    </Box>
  );
}
export default SearchBar;
