/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Avatar, Button, Checkbox, FormControlLabel, Grid, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RWTextInput from '../TextInput/RWTextInput';
import {
  fetchUpdateUserProfile, fetchUpsertUserConfig, fetchGetUserProfile, fetchGetUserConfig, useGetUserProfile,
} from '../../generated/realmWorldsApiComponents';
import { useAuth } from '../../hook/use-auth';
import useNotification from '../../hook/useNotification';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  height: '75vh',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  p: 4,
  outline: 'none',
  overflowX: 'hidden',
};

const contentStyle = {
  background: '#585858',
  color: '#FFFFFF',
};

const unselectedStyle = {
  py: 1,
  cursor: 'pointer',
  background: '#181818',
  color: '#FFFFFF',
};
const selectedStyle = {
  py: 1,
  background: '#585858',
  color: '#FFFFFF',
  mr: -1,
};

type RWModalProps = {
  open: boolean,
  onClose: () => void,
  setOpen: (v: boolean) => void,
  onConfirm: () => void
};

export default function AccountModal({
  open, onClose, setOpen,
}: RWModalProps) {
  const { displayNotification } = useNotification();
  const handleClose = () => { onClose?.(); setOpen(false); };
  const auth = useAuth();
  // Page State
  const [selected, setSelected] = React.useState<any>('Account Details');
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [user, setUser] = React.useState<any>(null);
  const [userConfig, setUserConfig] = React.useState<any>(null);
  // user data
  const [displayName, setDisplayName] = React.useState<any>('');
  const [location, setLocation] = React.useState<any>('');
  const [bio, setBio] = React.useState<any>('');
  const [userEmail, setUserEmail] = React.useState<any>('');
  // config
  const [showInTool, setShowInTool] = React.useState<any>(false);

  const { data: userObj } = useGetUserProfile({});

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchGetUserProfile({}).then((res) => {
        setUser(res);
        setDisplayName(res.username);
        setLocation(res.location);
        setBio(res.bio);
      }).catch((err) => {
        console.error('error fetching user profile', err);
      });
      fetchGetUserConfig({}).then((res) => {
        setUserConfig(res);

        setShowInTool(res.showInToolByDefault);
      }).catch((err) => {
        console.error('error fetching user config', err);
      });
    }
    return () => { isMounted = false; };
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    displayNotification({ message: 'Saving user profile updates...', type: 'info' });
    try {
      if (showInTool !== userConfig?.showInToolByDefault) {
        const configRes = await fetchUpsertUserConfig({
          body: {
            showInToolByDefault: showInTool,
          },
        });

        if (configRes.showInToolByDefault !== undefined) {
          setShowInTool(configRes.showInToolByDefault || false);
        } else {
          displayNotification({ message: 'Error saving user config', type: 'error' });
          return;
        }
      }
      const userProfile = await fetchUpdateUserProfile({
        body: {
          username: displayName,
          location,
          bio,
        },
      });
      if (userProfile.username !== undefined) {
        setDisplayName(userProfile.username);
      }
      if (userProfile.email !== undefined) {
        setUserEmail(userProfile.email);
      }
      if (userProfile.location !== undefined) {
        setLocation(userProfile.location);
      }
      if (userProfile.bio !== undefined) {
        setBio(userProfile.bio);
      }
    } catch (e) {

      // notify the user that there was an error updaing the profile
    }
    displayNotification({ message: 'User config saved', type: 'success' });
    setIsLoading(false);
  };

  const getSelectedContent = () => {
    switch (selected) {
      case 'Account Details':
        return (
          <Box minHeight="70vh">
            <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ mt: 1 }}>
              <Typography variant="h6" component="h2" sx={{ display: 'inline-block' }} textAlign="center">
                Account Details
              </Typography>
            </Box>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ mt: 8 }}>
                  <Avatar
                    alt=""
                    src={user?.profileImageUrl ?? auth?.user?.photoURL ?? 'No Image'}
                    sx={{ width: 150, height: 150, margin: 'auto' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ mt: 6 }}>
                  <Typography variant="h4" sx={{ display: 'inline-block' }} textAlign="center">
                    { displayName }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ mt: 1 }}>
                  <Typography variant="h6" sx={{ display: 'inline-block' }} textAlign="center">
                    { auth?.user?.email }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Grid container spacing={0} sx={{ mt: 5 }}>
                  <Grid item xs={1}>
                    <Typography variant="h6" component="h2" textAlign="end">
                      Name
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <RWTextInput
                      required
                      fullWidth
                      variant="standard"
                      id="name"
                      fontColor="#000000"
                      value={displayName}
                      sx={{ ml: 2 }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setDisplayName(event.target.value);
                      }}
                      InputProps={{ disableUnderline: true, inputProps: { maxLength: 75 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Grid container spacing={0} sx={{ mt: 5 }}>
                  <Grid item xs={1}>
                    <Typography variant="h6" component="h2" textAlign="end">
                      Location
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <RWTextInput
                      required
                      fullWidth
                      variant="standard"
                      id="location"
                      fontColor="#000000"
                      value={location}
                      sx={{ ml: 2 }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setLocation(event.target.value);
                      }}
                      InputProps={{ disableUnderline: true, inputProps: { maxLength: 75 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Grid container spacing={0} sx={{ mt: 5 }}>
                  <Grid item xs={1}>
                    <Typography variant="h6" component="h2" textAlign="end">
                      Bio
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <RWTextInput
                      required
                      fullWidth
                      multiline
                      maxRows={5}
                      variant="standard"
                      id="bio"
                      fontColor="#000000"
                      value={bio}
                      sx={{ ml: 2, height: '10vh' }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setBio(event.target.value);
                      }}
                      InputProps={{ disableUnderline: true, style: { color: '#000000' }, inputProps: { maxLength: 5000 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2} />
              <Grid item xs={7} sx={{ mt: 3 }}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      onChange={(e) => setShowInTool(e.target.checked)}
                      value={showInTool}
                      checked={showInTool}
                      color="primary"
                    />
)}
                  value={showInTool}
                  label={(
                    <p>
                      Always Show Tokens in Extension?
                    </p>
                                            )}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ my: 5 }}>
                  <LoadingButton
                    loading={isLoading}
                    disabled={isLoading}
                    variant="contained"
                    sx={{ mt: 5 }}
                    onClick={handleSave}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>

          </Box>
        );
      // case 'My Subscriptions':
      //   return (
      //     <Box minHeight="70vh">
      //       <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ mt: 1 }}>
      //         <Typography variant="h6" component="h2" sx={{ display: 'inline-block' }} textAlign="center">
      //           My Subscriptions
      //         </Typography>
      //       </Box>
      //       <Grid container spacing={0}>
      //         <Grid item xs={12}>
      //           <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ mt: 8 }}>
      //             <Avatar
      //               alt={user?.username || 'user'}
      //               src={user?.profileImageUrl || ''}
      //               sx={{ width: 150, height: 150, margin: 'auto' }}
      //             />
      //           </Box>
      //         </Grid>
      //         <Grid item xs={12} sx={{ mb: 5 }}>
      //           <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ mt: 6 }}>
      //             <Typography variant="h4" sx={{ display: 'inline-block' }} textAlign="center">
      //               { user.username || user?.email}
      //             </Typography>
      //           </Box>
      //         </Grid>
      //         <Grid item xs={5}>
      //           <Box
      //             display="flex"
      //             flexDirection="column"
      //             alignContent="center"
      //             alignItems="center"
      //             sx={{
      //               mt: 1, border: '2px solid #FF6746', borderRadius: '5px', height: '20vh', background: '#292929',
      //             }}
      //           >
      //             <Typography variant="h4" component="h2" sx={{ display: 'inline-block', mb: 3 }} textAlign="center">
      //               Free
      //             </Typography>
      //             <Box
      //               display="flex"
      //               flexDirection="column"
      //               alignContent="center"
      //               alignItems="center"
      //               justifyContent="center"
      //               sx={{
      //                 background: '#585858', width: '80%', height: '80%',
      //               }}
      //             >
      //               <Typography variant="h6" component="h2" sx={{ display: 'inline-block', mt: 1, mb: 1 }} textAlign="center">
      //                 Token Maker Tool
      //               </Typography>
      //               <Typography variant="h6" component="h2" sx={{ display: 'inline-block', mb: 1 }} textAlign="center">
      //                 Accessible Cloud Storage
      //               </Typography>
      //               <Typography variant="h6" component="h2" sx={{ display: 'inline-block', mb: 1 }} textAlign="center">
      //                 Extension Access
      //               </Typography>
      //             </Box>
      //             <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ ma: 3, mb: 1 }}>
      //               <Button size="large" disabled variant="contained" sx={{ mt: 2, mb: 1 }} onClick={() => {}}>
      //                 Current
      //               </Button>
      //             </Box>
      //           </Box>
      //         </Grid>
      //         <Grid xs={2} />
      //         <Grid item xs={5}>
      //           <Box
      //             display="flex"
      //             flexDirection="column"
      //             alignContent="center"
      //             alignItems="center"
      //             sx={{
      //               mt: 1, border: '1px solid #ffffff', borderRadius: '5px', height: '20vh', background: '#292929',
      //             }}
      //           >
      //             <Typography variant="h4" component="h2" sx={{ display: 'inline-block', mb: 3 }} textAlign="center">
      //               Pro
      //             </Typography>
      //             <Box
      //               display="flex"
      //               flexDirection="row"
      //               alignContent="center"
      //               alignItems="center"
      //               justifyContent="center"
      //               sx={{
      //                 background: '#585858', width: '80%', height: '80%',
      //               }}
      //             >
      //               <Typography variant="h6" component="h2" sx={{ display: 'inline-block' }} textAlign="center">
      //                 Coming Soon!
      //               </Typography>
      //             </Box>
      //             <Box display="flex" flexDirection="column" alignContent="center" alignItems="center" sx={{ ma: 3, mb: 1 }}>
      //               <Button size="large" disabled variant="contained" sx={{ mt: 2, mb: 0 }} onClick={() => {}}>
      //                 Upgrade
      //               </Button>
      //             </Box>
      //           </Box>
      //         </Grid>
      //       </Grid>
      //     </Box>
      //   );
      default:
        return (
          <Typography variant="h6" component="h2" sx={{ display: 'inline-block' }} textAlign="center">
            There was a problem loading this page.
          </Typography>
        );
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} display="flex" flexDirection="column" alignContent="center" margin="auto">
          { auth?.user ? (
            <Box
              className="hide-scroll-bars"
              sx={{
                background: '#303030', border: '1px solid #FFFFFF', borderRadius: 5, minWidth: '65vw', height: '100%', margin: 'auto', overflowY: 'scroll', overflowX: 'hidden',
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" sx={{ background: '#181818', height: '100%', borderRight: '1px solid #FFFFFF' }}>
                    <Box sx={{ width: '100%' }}>
                      <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center" sx={selected === 'Account Details' ? selectedStyle : unselectedStyle} onClick={() => setSelected('Account Details')}>
                        <Box>
                          <Typography variant="h6" component="h2" sx={{ display: 'inline-block' }}>
                            Account Details
                          </Typography>
                        </Box>
                      </Box>
                      {/* <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center" sx={selected === 'My Subscriptions' ? selectedStyle : unselectedStyle} onClick={() => setSelected('My Subscriptions')}>
                        <Box>
                          <Typography variant="h6" component="h2" sx={{ display: 'inline-block' }} textAlign="center">
                            My Subscriptions
                          </Typography>
                        </Box>
                      </Box> */}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box display="flex" flexDirection="row" justifyContent="center" sx={contentStyle}>
                    { getSelectedContent() }
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (<Typography variant="h6" component="h2" sx={{ display: 'inline-block' }}>Please login to view your account</Typography>) }
        </Box>
      </Modal>
    </div>
  );
}
