/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
import { useDispatch, useSelector } from 'react-redux';
import { StageData } from '../redux/currentStageData';
import { TokenActions, TokenState, tokenSelector } from '../redux/token';

export type TokenData = {
  id: string | null;
  name: string;
  description?: string;
  droppedImageBlob?: Blob;
  edits?: StageData[];
  edited?: boolean
} & Record<string, any>;

export type TokenProps = {
  key: string;
  data: TokenData;
  e?: Event;
} & Record<string, any>;

export type OverrideTokenData<T> = Omit<TokenData, keyof T> & T;

const useToken = () => {
  const dispatch = useDispatch();

  const token = useSelector(tokenSelector);

  const setToken = (tokenState: TokenState) => {
    dispatch(TokenActions.setToken(tokenState));
  };

  const updateToken = (attrsFunc: (data: TokenState['data']) => TokenState['data']) => {
    const newData = attrsFunc(token);
    const updatedObject = {
      ...(token ?? {}),
      data: {
        ...(token ? token.data : {}),
        edited: true,
        ...newData,
      },
    } as TokenState;
    dispatch(TokenActions.setToken(updatedObject));
  };

  const clearToken = () => {
    dispatch(TokenActions.clearToken());
  };

  return {
    token,
    setToken,
    updateToken,
    clearToken,
  } as const;
};

export default useToken;
