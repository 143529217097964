/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  AppBar,
  ThemeProvider,
  Toolbar,
  createTheme,
} from '@mui/material';
import { Image } from 'mui-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  useNavigate,
} from 'react-router-dom';
import Link from '@mui/material/Link';
import LoginBox from '../components/auth/LoginBox';

function Copyright(props: any) {
  return (
    <AppBar position="fixed" elevation={0} sx={{ backgroundColor: 'transparent', top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Grid container alignItems="flex-end">
          <Grid item xs={12} md={5} py={3} sx={{ backgroundColor: '#1B1B22' }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2" color="common.white" align="left" {...props}>
                  {'© '}
                  {`${new Date().getFullYear()} `}
                  <Link color="inherit" href="#">
                    Realm Worlds
                  </Link>
                  . All rights reserved.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="right">
                  <Grid item px={1}>
                    <Button href="https://realmworlds.net/" sx={{ backgroundColor: 'white', minWidth: 0 }} target="_blank">
                      <FontAwesomeIcon color="black" icon={solid('globe')} size="xl" />
                    </Button>
                  </Grid>
                  <Grid item px={1}>
                    <Button href="https://www.reddit.com/r/RealmWorlds" sx={{ backgroundColor: 'white', minWidth: 0 }} target="_blank">
                      <FontAwesomeIcon color="black" icon={brands('reddit')} size="xl" />
                    </Button>
                  </Grid>
                  <Grid item px={1}>
                    <Button href="#contained-buttons" sx={{ backgroundColor: 'white', minWidth: 0 }} target="_blank">
                      <FontAwesomeIcon color="black" icon={brands('youtube')} size="xl" />
                    </Button>
                  </Grid>
                  <Grid item px={1}>
                    <Button href="https://discord.gg/pndajRXRRg" sx={{ backgroundColor: 'white', minWidth: 0 }} target="_blank">
                      <FontAwesomeIcon color="black" icon={brands('discord')} size="xl" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ backgroundColor: '#1B1B22' }}>
            <Box flexGrow={1} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#FF6746',
      main: '#FF6746',
      dark: '#262626',
      contrastText: '#fff',
    },
  },
});

export default function Login() {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" elevation={0} sx={{ backgroundColor: 'transparent' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} />
            <Typography>Need an account?</Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={() => { navigate('/signup'); }}
              sx={{ my: 3, ml: 3, px: 3 }}
            >
              Sign Up
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Container component="main" maxWidth={false} disableGutters sx={{ backgroundColor: '#1B1B22' }}>
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={5} px={5} pt={3}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <Box component="img" src="./images/rw_logo.png" width={100} />
            </Grid>
            <Typography px={3} variant="h3" align="center" color="common.white" fontWeight={600}>Login to Realm Worlds</Typography>
            <Typography mt={2} align="justify" color="common.white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</Typography>
            <LoginBox onLogin={() => { navigate('/'); }} />
            <Copyright />
          </Grid>
          <Grid item display={{ xs: 'none', md: 'block' }} md={7}>
            <Image height="100vh" fit="cover" src="./images/Azamel_sorcerer_snake.png" />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
